import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

const useSubmitInformationRequestPreferred = () => {
  return useMutation({
    mutationKey: ['submitInformationRequestPreferred'],
    mutationFn: async () => {
      return apiClient
        .post(
          'api/supplier-experience/information-request/preferred-term',
          undefined satisfies paths['/information-request/preferred-term']['post']['requestBody']
        )
        .json<paths['/information-request/preferred-term']['post']['responses']['204']['content']>();
    },
  });
};

export default useSubmitInformationRequestPreferred;
