import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, cn } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { ArrowDownIcon, ArrowUpIcon } from '@c2fo/liquidity/icons';

/** TABLE */
export const Table = forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => <table ref={ref} className={cn('w-full', className)} {...props} />
);
Table.displayName = 'Table';

/** TABLE HEADER */
export const TableHeader = forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={cn('border-b-4 [&_tr]:border-b', className)} {...props} />
  )
);
TableHeader.displayName = 'TableHeader';

/** TABLE BODY */
interface TableSectionElement extends React.HTMLAttributes<HTMLTableSectionElement> {
  /**
   * Whether the table is loading or not.
   */
  loading?: boolean;
}

export const TableBody = forwardRef<HTMLTableSectionElement, TableSectionElement>(
  ({ className, loading = false, ...props }, ref) => (
    <tbody
      ref={ref}
      className={cn('[&_tr:last-child]:border-0', className, {
        'blur-sm': loading,
      })}
      {...props}
    />
  )
);
TableBody.displayName = 'TableBody';

/** TABLE ROW */
export const TableRow = forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => <tr ref={ref} className={cn('border-b', className)} {...props} />
);
TableRow.displayName = 'TableRow';

/** TABLE HEAD */
export const TableHead = forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & { condensed?: boolean }
>(({ className, condensed = false, ...props }, ref) => (
  <th
    ref={ref}
    scope="col"
    className={cn(
      'whitespace-nowrap p-4 text-left text-sm font-medium uppercase first-of-type:pl-8 last-of-type:pr-8',
      { 'cursor-pointer transition-all duration-200 hover:bg-deepBlue-50': props.onClick },
      { 'first-of-type:pl-4 last-of-type:pr-4': condensed },
      className
    )}
    {...(props.onClick && { role: 'button' })}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

/** TABLE CELL */
export const TableCell = forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & { condensed?: boolean }
>(({ className, condensed = false, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'whitespace-nowrap p-4 first-of-type:pl-8 last-of-type:pr-8',
      { 'first-of-type:pl-4 last-of-type:pr-4': condensed },
      className
    )}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

/** TABLE MESSAGE */
export const TableMessage = ({ colSpan, message }: { colSpan: number; message: string }) => (
  <tr>
    <TableCell className="text-center text-gray-500" colSpan={colSpan}>
      {message}
    </TableCell>
  </tr>
);
TableMessage.displayName = 'TableMessage';

/** TABLE DISPLAY */
export const TableDisplay = ({ colSpan, children }: { colSpan: number; children: React.ReactNode }) => (
  <tr>
    <TableCell colSpan={colSpan}>{children}</TableCell>
  </tr>
);
TableDisplay.displayName = 'TableDisplay';

// TABLE PAGINATION
export const TablePagination = ({
  className,
  onNextChange,
  onPrevChange,
  onPageSizeChange,
  pageSize,
  disablePrev,
  disableNext,
}: {
  className?: string;
  disableNext?: boolean;
  disablePrev?: boolean;
  onNextChange: () => void;
  onPrevChange: () => void;
  onPageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  pageSize: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex justify-between border-t px-8 py-6', className)}>
      <div className="flex items-center gap-2">
        <div>{t('taker.recordsPerPage')}</div>
        <select className="rounded-md border p-1.5" onChange={(e) => onPageSizeChange(e)} value={pageSize}>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="flex items-center gap-2">
        <div>
          <Button variant="secondary" size="sm" onClick={onPrevChange} disabled={disablePrev ?? false}>
            {t('core.previousPage')}
          </Button>
        </div>
        <div>
          <Button variant="secondary" size="sm" onClick={onNextChange} disabled={disableNext ?? false}>
            {t('core.nextPage')}
          </Button>
        </div>
      </div>
    </div>
  );
};

TablePagination.displayName = 'TablePagination';

/** TABLE SORT ARROW */
export type TableSortDirection = 'asc' | 'desc';

export function TableSortArrow<T>({
  accessorKey,
  children,
  sort,
  textRight,
}: {
  accessorKey: T;
  children: React.ReactNode;
  sort: { key: T; direction: TableSortDirection } | null;
  textRight?: boolean;
}) {
  const arrowIconProps = {
    fill: sort?.key === accessorKey ? colors.secondary[500] : 'transparent',
  };
  const ArrowIcon = (
    <div>
      {sort?.direction === 'desc' ? <ArrowDownIcon {...arrowIconProps} /> : <ArrowUpIcon {...arrowIconProps} />}
    </div>
  );

  return (
    <div className={cn('flex items-center space-x-1', textRight ? 'justify-end' : 'justify-start')}>
      {textRight && ArrowIcon}
      <div>{children}</div>
      {!textRight && ArrowIcon}
    </div>
  );
}
