import { cn, Label } from '@c2fo/liquidity';

interface RadioInputProps {
  checked: boolean;
  description: string;
  label: string;
  onChange: () => void;
  value: string;
}

const RadioInput = ({ checked, description, label, onChange, value }: RadioInputProps) => (
  <Label
    htmlFor={value}
    className={cn(
      'flex w-full cursor-pointer items-start rounded border border-gray-200 p-2 transition-colors duration-200 hover:border-primary-300 hover:bg-primary-50',
      {
        'border-primary-500 bg-primary-100 hover:border-primary-500 hover:bg-primary-100': checked,
      }
    )}
  >
    <span className="flex items-center gap-3">
      <input
        id={value}
        className="h-4 w-4 cursor-pointer accent-green-500"
        checked={checked}
        name={value}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <span>{label}</span>
    </span>
    <p className="ml-7 text-sm font-normal text-gray-800">{description}</p>
  </Label>
);

export default RadioInput;
