import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useRefetchStatsQueries,
  useServerSideEventListeners,
} from '@/lib/serverSentEvents/useServerSideEventListeners';
import { fetchSEAInvoiceRules, TakerMarketWithRecurringRule } from '../../../data/useRecurringRules';
import useTakerMarkets from '../../../data/useTakerMarkets';
import useFeature, { Features } from '../../../lib/features';
import sortRulesTable from './sortRulesTable';

export const useRefetchAndSubscribeForRules = () => {
  const [enableSEARulesApi] = useFeature(Features['enterprise-ui_enableSeaRules']);

  const queryClient = useQueryClient();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { data: allTakerMarkets = [] } = useTakerMarkets();

  const refetchSEASupplierMarketsRules = useCallback(
    async (takerMarkets: { marketUuid: string; takerUuid: string }[]) => {
      if (takerMarkets.length === 0) {
        return;
      }

      try {
        const updatedInvoiceRules = await fetchSEAInvoiceRules(allTakerMarkets, {
          supplierMarkets: takerMarkets.map((tm) => ({
            marketUuid: tm.marketUuid,
            supplierDivisionUuid: tm.takerUuid,
          })),
        });

        if (updatedInvoiceRules) {
          queryClient.setQueryData<TakerMarketWithRecurringRule[]>(['recurring-rules'], (prevData = []) => {
            // Create a copy of the previous data
            const newInvoiceRules = [...prevData];

            // Add the new rules for the specific supplier market
            updatedInvoiceRules.forEach((updatedRule) => {
              const invoiceRuleIndex = newInvoiceRules.findIndex(
                (invoiceRule) =>
                  invoiceRule.rule.marketUuid === updatedRule.rule.marketUuid &&
                  invoiceRule.rule.takerUuid === updatedRule.rule.takerUuid
              );

              if (invoiceRuleIndex !== -1) {
                // Update the existing rule
                newInvoiceRules[invoiceRuleIndex] = updatedRule;
              } else {
                // Add the new rule at the end of the array and later sort it based on eligibleInvoiceAmount
                newInvoiceRules.push(updatedRule);
              }
            });

            return sortRulesTable(newInvoiceRules, { key: 'eligibleInvoiceAmount', direction: 'desc' });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [queryClient, allTakerMarkets]
  );

  const refetchAndSubscribeRules = useCallback(
    ({
      makerOrganizationUuid,
      takerMarkets,
    }: {
      makerOrganizationUuid?: string;
      takerMarkets: {
        marketUuid: string;
        takerId: number;
        takerUuid: string;
      }[];
    }) => {
      if (enableSEARulesApi) {
        refetchSEASupplierMarketsRules(takerMarkets);
      } else {
        queryClient.refetchQueries({ queryKey: ['recurring-rules', makerOrganizationUuid] });
      }

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        refetchOneTakerMarket({ marketUuid, takerId });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats', marketUuid, takerId] });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats-filtered', marketUuid, takerId] });
      });

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        subscribeToMarketStats({
          marketUuid,
          takerId,
        });
      });
    },
    [queryClient, enableSEARulesApi, refetchOneTakerMarket, subscribeToMarketStats, refetchSEASupplierMarketsRules]
  );

  return { refetchAndSubscribeRules };
};

export default useRefetchAndSubscribeForRules;
