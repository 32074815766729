import { useTranslation } from 'react-i18next';

const useRuleTitles = () => {
  const { t } = useTranslation();

  const getDpeRule = ({ gteDpe, lteDpe }: { gteDpe?: number; lteDpe?: number }) => {
    if (!gteDpe && !lteDpe) {
      return;
    }

    if (gteDpe && lteDpe) {
      return {
        title: t('recurringRules.dpe.ltegte.short', { lteDpe, gteDpe }),
        description: t('recurringRules.dpe.ltegte.long', { lteDpe, gteDpe }),
      };
    } else if (gteDpe) {
      return {
        title: t('recurringRules.dpe.gte.short', { gteDpe }),
        description: t('recurringRules.dpe.gte.long', { gteDpe }),
      };
    } else if (lteDpe) {
      return {
        title: t('recurringRules.dpe.lte.short', { lteDpe }),
        description: t('recurringRules.dpe.lte.long', { lteDpe }),
      };
    }
  };

  const getDueDateRule = ({ fromDueDate, toDueDate }: { fromDueDate?: string; toDueDate?: string }) => {
    if (!fromDueDate && !toDueDate) {
      return;
    }

    if (fromDueDate && toDueDate) {
      return {
        title: t('recurringRules.dueDate.ltgt.short', { toDueDate, fromDueDate }),
        description: t('recurringRules.dueDate.ltgt.long', { toDueDate, fromDueDate }),
      };
    } else if (fromDueDate) {
      return {
        title: t('recurringRules.dueDate.gt.short', { fromDueDate }),
        description: t('recurringRules.dueDate.gt.long', { fromDueDate }),
      };
    } else if (toDueDate) {
      return {
        title: t('recurringRules.dueDate.lt.short', { toDueDate }),
        description: t('recurringRules.dueDate.lt.long', { toDueDate }),
      };
    }
  };

  const getInvoiceAmountRule = ({
    gteInvoiceAmount,
    lteInvoiceAmount,
  }: {
    gteInvoiceAmount?: string;
    lteInvoiceAmount?: string;
  }) => {
    if (!gteInvoiceAmount && !lteInvoiceAmount) {
      return;
    }

    if (gteInvoiceAmount && lteInvoiceAmount) {
      return {
        title: t('recurringRules.invoiceAmount.ltegte.short', { lteInvoiceAmount, gteInvoiceAmount }),
        description: t('recurringRules.invoiceAmount.ltegte.long', { lteInvoiceAmount, gteInvoiceAmount }),
      };
    } else if (gteInvoiceAmount) {
      return {
        title: `≥ ${gteInvoiceAmount}`,
        description: t('recurringRules.invoiceAmount.gte.long', { gteInvoiceAmount }),
      };
    } else if (lteInvoiceAmount) {
      return {
        title: `≤ ${lteInvoiceAmount}`,
        description: t('recurringRules.invoiceAmount.lte.long', { lteInvoiceAmount }),
      };
    }
  };

  const getInvoiceIdsRule = (excludedInvoiceIds?: string[]) => {
    if (excludedInvoiceIds && excludedInvoiceIds.length) {
      return {
        title: t('recurringRules.invoiceId.short', {
          excludedInvoiceIds: `"${excludedInvoiceIds.join('", "')}"`,
        }),
        description: t('recurringRules.invoiceId.long', {
          excludedInvoiceIds: `"${excludedInvoiceIds.join('", "')}"`,
        }),
      };
    }

    return;
  };

  return {
    getDpeRule,
    getDueDateRule,
    getInvoiceAmountRule,
    getInvoiceIdsRule,
  };
};

export default useRuleTitles;
