/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, useSnackbar } from '@c2fo/liquidity';
import { TechnicalDifficultyIcon } from '@c2fo/liquidity/icons';
import invoices from '@/assets/invoicesgrey.svg?url';
import DpeRangePicker from '@/components/DpeRangePicker';
import EligibleInvoicesRangePicker from '@/components/EligibleInvoicesRangePicker';
import { useInvoiceLayoutOutletContext } from '@/components/InvoiceLayout';
import NoDataState from '@/components/NoDataState';
import QueryBoundaries from '@/components/QueryBoundaries';
import RangePicker from '@/components/RangePicker';
import { TableSortDirection } from '@/components/Table';
import useBulkInvoices from '@/data/useBulkInvoices';
import {
  EligibleInvoice,
  EligibleInvoiceFetchFilters,
  EligibleInvoiceFetchOptions,
  EligibleInvoiceOrder,
  EligibleInvoiceAmount,
  EligibleInvoiceDpe,
} from '@/data/useEligibleInvoices';
import useInvoices from '@/data/useInvoices';
import EligibleInvoiceTable from '@/features/eligibleInvoiceTable/EligibleInvoiceTable';
import {
  isExcluded,
  isIncluded,
  isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment,
  selectedInvoiceReducer,
} from '@/features/eligibleInvoiceTable/utils';
import QuickActionCreateRule from '@/features/recurringRules/components/QuickActionCreateRule';
import RecurringRulesForm, { RecurringRuleFormValues } from '@/features/recurringRules/RecurringRulesForm';
import useGetRecurringRulesForTakerMarkets from '@/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import addOrRemoveFromArray from '@/utils/addOrRemoveFromArray';
import getMarketType from '@/utils/getMarketType';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import useUrlState from '@/utils/useUrlState';

export interface EligibleInvoiceTableSort {
  key: EligibleInvoiceOrder;
  direction: TableSortDirection;
}

const EligibleInvoices = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asCurrency } = useLocaleFormat();
  const queryClient = useQueryClient();
  const { mutate: toggleInvoices } = useInvoices();
  const { mutate: bulkExclude } = useBulkInvoices();
  const [showDueDatePicker, setShowDueDatePicker] = useState<boolean>(false);
  const [showDpeRangePicker, setShowDpeRangePicker] = useState<boolean>(false);
  const [showEligibleInvoiceRangePicker, setShowEligibleInvoiceRangePicker] = useState<boolean>(false);
  const [recurringRulesModalOpen, setRecurringRulesModalOpen] = useState<boolean>(false);
  const [ruleModalDefaultValues, setRuleModalDefaultValues] = useState<Partial<RecurringRuleFormValues>>({});
  const { getRestrictions } = useRestrictions();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();

  const { subscribeToMarketStats } = useServerSideEventListeners();
  const [urlState, setUrlState] = useUrlState<EligibleInvoiceFetchOptions>({
    limit: 100,
    order: 'amount',
    orderDirection: 'desc',
    page: 1,
  });
  const [selectedInvoiceState, selectedInvoiceDispatch] = useReducer(selectedInvoiceReducer, {
    enableExcludeButton: false,
    enableIncludeButton: false,
    isBulk: false,
    selectedInvoices: [],
    selectedInvoiceAmount: 0,
    selectedInvoiceCount: 0,
  });
  const { loader, takerMarket } = useInvoiceLayoutOutletContext();
  const { setLoading } = loader;
  const { canEditRecurringRules } = getRestrictions([takerMarket]);

  const showRecurringRulesModal = (selectedDefaultValues: Partial<RecurringRuleFormValues>) => {
    const defaults: Partial<RecurringRuleFormValues> = {
      ...selectedDefaultValues,
      takerMarkets: [takerMarket],
    };

    setRuleModalDefaultValues(defaults);
    setRecurringRulesModalOpen(true);

    track('recurring-rules::opened', {
      referrer: 'division-rules',
    });
  };

  const closeRecurringRulesModal = () => {
    setRecurringRulesModalOpen(false);
    track('recurring-rules::closed');
  };

  const sort: EligibleInvoiceTableSort = {
    key: urlState.order ?? 'amount',
    direction: urlState.orderDirection ?? 'desc',
  };

  const handleSort = (key: EligibleInvoiceOrder) => {
    setUrlState((f) => ({
      ...f,
      order: key,
      orderDirection: f.order === key && f.orderDirection === 'desc' ? 'asc' : 'desc',
      page: 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-sort::clicked', { key });
  };

  const baseValues = {
    amount: [',25000', '25000,50000', '50000,75000', '75000,'] satisfies EligibleInvoiceAmount[],
    dpe: ['0,9', '10,20', '21,30', '31,60', '61,'] satisfies EligibleInvoiceDpe[],
  };

  const handleFilter = ({
    key,
    value,
  }: {
    key: keyof Omit<EligibleInvoiceFetchFilters, 'search'>;
    value?: boolean | string;
  }) => {
    // handle custom range inputs for amount and dpe
    if (key === 'amount' || key === 'dpe') {
      // if not a custom range, set the value and remove any value that is not in the base values
      if ((baseValues[key] as string[]).includes(value as string)) {
        setUrlState((f) => ({
          ...f,
          filter: {
            ...f.filter,
            [key]: addOrRemoveFromArray(f.filter?.[key] ?? [], value).filter((value) =>
              (baseValues[key] as string[]).includes(value as string)
            ),
          },
          page: 1,
        }));
      } else {
        // if custom range, set the value
        setUrlState((f) => ({
          ...f,
          filter: {
            ...f.filter,
            [key]: [value],
          },
          page: 1,
        }));
      }
    } else {
      setUrlState((f) => ({
        ...f,
        filter: {
          ...f.filter,
          [key]: key === 'dueDate' ? [value] : addOrRemoveFromArray(f.filter?.[key] ?? [], value),
        },
        page: 1,
      }));
    }

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-filter::clicked', { key, value });
  };

  const handleSearch = (search: string) => {
    setUrlState((f) => {
      let { filter } = f;

      if (!search) {
        delete filter?.search;
      } else {
        filter = { ...filter, search };
      }

      return {
        ...f,
        filter,
        page: 1,
      };
    });

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-search::submitted', { value: search });
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = Number(e.target.value);

    setUrlState((f) => ({
      ...f,
      limit,
      page: 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-page-size::clicked', { size: limit });
  };

  const handleNextChange = () => {
    setUrlState((f) => ({
      ...f,
      page: f.page! + 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-page::clicked');
  };

  const handlePrevChange = () => {
    setUrlState((f) => ({
      ...f,
      page: f.page! - 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-page::clicked');
  };

  const handleInvoiceSelect = (invoice: EligibleInvoice) => {
    selectedInvoiceDispatch({
      type: 'invoiceClicked',
      payload: { invoice },
    });

    track('invoice-select::clicked');
  };

  const toggleInvoiceInclusionExclusion = (action: 'include' | 'exclude') => {
    let invoicesToChange: EligibleInvoice[] = [];
    setLoading(true);

    if (action === 'exclude') {
      invoicesToChange = selectedInvoiceState.selectedInvoices
        .filter((invoice) => isIncluded(invoice))
        .filter((invoice) => !isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment(invoice));
    }

    if (action === 'include') {
      invoicesToChange = selectedInvoiceState.selectedInvoices.filter((invoice) => isExcluded(invoice));
    }

    if (!invoicesToChange.length) {
      setLoading(false);
      return;
    }

    invoicesToChange.forEach((invoice) => {
      const params = {
        takerId: takerMarket.takerDivisionId,
        marketUuid: takerMarket.marketUuid,
        invoice: {
          ...invoice,
          takerExcluded: action === 'exclude',
        },
      };

      toggleInvoices(params, {
        onSuccess: (_data, variables) => {
          setLoading(false);
          selectedInvoiceDispatch({ type: 'unselectAll' });
          showSnackbar({ message: t('invoices.updated') });

          queryClient.refetchQueries({ queryKey: ['eligible-invoices'] });

          subscribeToMarketStats({
            marketUuid: variables.marketUuid,
            takerId: variables.takerId,
          });
        },
        onError: () => {
          setLoading(false);
          showSnackbar({ message: t('invoices.errorUpdating') });
        },
      });
    });

    track(`invoice-${action}::submitted`, {
      takerId: takerMarket.takerDivisionId,
      takerDivisionUuid: takerMarket.takerDivisionUuid,
      marketId: takerMarket.marketId,
      marketUuid: takerMarket.marketUuid,
      invoicesToChange: invoicesToChange.map((invoice) => invoice.id),
      action,
    });
  };

  const toggleBulkInvoiceInclusionExclusion = async (action: 'include' | 'exclude') => {
    setLoading(true);

    const params = {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
      exclude: action === 'exclude',
      filters: urlState.filter,
    };

    bulkExclude(params, {
      onSuccess: () => {
        setLoading(false);
        selectedInvoiceDispatch({ type: 'unselectAll' });
        showSnackbar({ message: t('invoices.updated') });
      },
      onError: () => {
        setLoading(false);
        showSnackbar({ message: t('invoices.errorUpdating') });
      },
    });

    track(`invoice-bulk-${action}::submitted`, {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  interface DpeRange {
    value: EligibleInvoiceDpe;
    labelKey: string;
    min?: number;
    max?: number;
  }

  const dpeRanges: DpeRange[] = [
    { value: '0,9', labelKey: 'core.rangeFilterLowest', max: 9 },
    { value: '10,20', labelKey: 'core.rangeFilterMiddle', min: 10, max: 20 },
    { value: '21,30', labelKey: 'core.rangeFilterMiddle', min: 21, max: 30 },
    { value: '31,60', labelKey: 'core.rangeFilterMiddle', min: 31, max: 60 },
    { value: '61,', labelKey: 'core.rangeFilterHighest', min: 61 },
  ];

  const dpe = urlState?.filter?.dpe?.[0];
  const dpeValues = dpe?.split(',').map(Number);
  const dpeMin = dpeValues?.[0];
  const dpeMax = dpeValues?.[1];

  interface EligibleInvoiceRanges {
    value: EligibleInvoiceAmount;
    labelKey: string;
    min?: string;
    max?: string;
  }

  const eligibleInvoiceRanges: EligibleInvoiceRanges[] = [
    { value: ',25000', labelKey: 'core.rangeFilterLowest', max: asCurrency(25000, takerMarket.currency) },
    {
      value: '25000,50000',
      labelKey: 'core.rangeFilterMiddle',
      min: asCurrency(25000, takerMarket.currency),
      max: asCurrency(50000, takerMarket.currency),
    },
    {
      value: '50000,75000',
      labelKey: 'core.rangeFilterMiddle',
      min: asCurrency(50000, takerMarket.currency),
      max: asCurrency(75000, takerMarket.currency),
    },
    { value: '75000,', labelKey: 'core.rangeFilterHighest', min: asCurrency(75000, takerMarket.currency) },
  ];

  const dueDateFrom = urlState.filter?.dueDate ? urlState.filter?.dueDate[0].split(',')[0] : null;
  const dueDateTo = urlState.filter?.dueDate ? urlState.filter?.dueDate[0].split(',')[1] : null;
  const dueDatePickerValue = {
    startDate: urlState.filter?.dueDate && dueDateFrom ? dueDateFrom : new Date().toISOString().split('T')[0],
    endDate: urlState.filter?.dueDate && dueDateTo ? dueDateTo : new Date().toISOString().split('T')[0],
  };
  const marketType = getMarketType(takerMarket);
  const isPreferred = marketType === 'PREFERRED';
  const recurringRule = getRecurringRulesForTakerMarkets([takerMarket])[0];

  return (
    <>
      <RecurringRulesForm
        defaultValues={ruleModalDefaultValues}
        isForSingleDivision
        onClose={closeRecurringRulesModal}
        open={recurringRulesModalOpen}
        referrer="divisionDetails"
        takerMarkets={[takerMarket]}
        type={marketType}
        {...((ruleModalDefaultValues.criteria?.length ?? 0) > 0 && { mode: 'edit' })}
      />
      <div className="flex border-t">
        {/* FILTERS */}
        <div className="w-44 shrink-0 border-r lg:w-64">
          <div className="px-8 py-6">
            <div className="pb-6 font-medium">{t('core.filters')}</div>
            <div className="space-y-6">
              <div className="text-sm">
                <div className="pb-3 font-medium">{t('core.included')}</div>
                <div className="space-y-3">
                  <Checkbox
                    checked={(urlState?.filter?.included ?? []).includes(true)}
                    color="primary"
                    id="included-true"
                    label={t('core.included')}
                    onChange={() => handleFilter({ key: 'included', value: true })}
                    value="true"
                  />
                  <Checkbox
                    checked={(urlState?.filter?.included ?? []).includes(false)}
                    color="primary"
                    id="included-false"
                    label={t('core.excluded')}
                    onChange={() => handleFilter({ key: 'included', value: false })}
                    value="false"
                  />
                </div>
              </div>
              <div className="text-sm">
                <div className="pb-3 font-medium">{t('core.clearing')}</div>
                <div className="space-y-3">
                  <Checkbox
                    checked={(urlState?.filter?.clearing ?? []).includes(true)}
                    color="primary"
                    id="clearing-true"
                    label={t('core.yes')}
                    onChange={() => handleFilter({ key: 'clearing', value: true })}
                    value="true"
                  />
                  <Checkbox
                    checked={(urlState?.filter?.clearing ?? []).includes(false)}
                    color="primary"
                    id="clearing-false"
                    label={t('core.no')}
                    onChange={() => handleFilter({ key: 'clearing', value: false })}
                    value="false"
                  />
                </div>
              </div>

              {/* Days Paid Early (DPE) Ranges */}
              <div className="text-sm">
                <div className="pb-3 font-medium">{t('core.daysPaidEarlyWithAbbreviation')}</div>
                <div className="space-y-3 lowercase">
                  {dpeRanges.map(({ value, labelKey, min, max }) => (
                    <Checkbox
                      key={value}
                      checked={(urlState?.filter?.dpe ?? []).includes(value)}
                      color="primary"
                      id={value}
                      label={t(labelKey, {
                        min: min ? `${min} ${t('core.days')}` : undefined,
                        max: max ? `${max} ${t('core.days')}` : undefined,
                      })}
                      onChange={() => handleFilter({ key: 'dpe', value })}
                      value={value}
                    />
                  ))}
                  {/* DPE Range Picker */}
                  <DpeRangePicker
                    onChange={(range) => {
                      if (range) {
                        handleFilter({ key: 'dpe', value: `${range.startDpe},${range.endDpe}` });
                      }
                    }}
                    onOpenChange={setShowDpeRangePicker}
                    open={showDpeRangePicker}
                    trigger={
                      (urlState?.filter?.dpe ?? []).every((value) => baseValues.dpe.includes(value)) ? (
                        t('core.customRange')
                      ) : (
                        <div className="space-y-3 text-left lowercase">
                          <Checkbox
                            checked
                            color="primary"
                            id="custom-dpe-range"
                            label={t('core.rangeFilterMiddle', {
                              min: dpeMin ? `${dpeMin} ${dpeMin > 1 ? t('core.days') : t('core.day')}` : undefined,
                              max: dpeMax ? `${dpeMax} ${dpeMax > 1 ? t('core.days') : t('core.day')}` : undefined,
                            })}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                handleFilter({ key: 'dpe', value: undefined });
                                setShowDpeRangePicker(false);
                              }
                            }}
                          />
                        </div>
                      )
                    }
                  />
                  {/* Recurring DPE Rule Quick Action */}
                  {canEditRecurringRules && !isPreferred && (
                    <QuickActionCreateRule ruleCategory="dpe" rule={recurringRule} onClick={showRecurringRulesModal} />
                  )}
                </div>
              </div>
              {/* Due Date Range Picker */}
              <div className="text-sm">
                <div className="pb-3 font-medium">{t('core.originalDueDate')}</div>
                <div className="space-y-3">
                  <RangePicker
                    onChange={(range) =>
                      handleFilter({
                        key: 'dueDate',
                        value: `${range?.startDate},${range?.endDate}`,
                      })
                    }
                    onOpenChange={setShowDueDatePicker}
                    open={showDueDatePicker}
                    trigger={
                      urlState.filter?.dueDate ? (
                        <div className="text-left capitalize">
                          {dueDateFrom && (
                            <div>
                              {t('core.from')} {dueDateFrom}
                            </div>
                          )}
                          {dueDateTo && (
                            <div>
                              {t('core.to')} {dueDateTo}
                            </div>
                          )}
                        </div>
                      ) : (
                        t('core.customRange')
                      )
                    }
                    value={dueDatePickerValue}
                  />
                  {urlState.filter?.dueDate && (
                    <button onClick={() => handleFilter({ key: 'dueDate', value: undefined })} type="button">
                      {t('core.clearDateRange')}
                    </button>
                  )}
                  {/* Recurring Date Rule Quick Action */}
                  {canEditRecurringRules && !isPreferred && (
                    <QuickActionCreateRule
                      ruleCategory="dueDate"
                      rule={recurringRule}
                      onClick={showRecurringRulesModal}
                    />
                  )}
                </div>
              </div>
              {/* Eligible Invoice Amount Ranges */}
              <div className="text-sm">
                <div className="pb-3 font-medium">{t('core.eligibleInvoiceAmount')}</div>
                <div className="space-y-3">
                  {eligibleInvoiceRanges.map(({ value, labelKey, min, max }) => (
                    <Checkbox
                      key={value}
                      checked={(urlState?.filter?.amount ?? []).includes(value)}
                      color="primary"
                      id={value}
                      label={t(labelKey, {
                        min: min ? `${min}` : undefined,
                        max: max ? `${max}` : undefined,
                      })}
                      onChange={() => handleFilter({ key: 'amount', value })}
                      value={value}
                    />
                  ))}
                  {/* Eligible Invoice Amount Range Picker */}
                  <EligibleInvoicesRangePicker
                    onChange={(range) => {
                      if (range) {
                        handleFilter({
                          key: 'amount',
                          value: `${range.startAmount},${range.endAmount}`,
                        });
                      }
                    }}
                    onOpenChange={setShowEligibleInvoiceRangePicker}
                    open={showEligibleInvoiceRangePicker}
                    trigger={
                      (urlState?.filter?.amount ?? []).every((value) => baseValues.amount.includes(value)) ? (
                        t('core.customRange')
                      ) : (
                        <div className="space-y-3 text-left">
                          <Checkbox
                            checked
                            color="primary"
                            id="custom-amount-range"
                            label={t('core.rangeFilterMiddle', {
                              min: asCurrency(
                                Number((urlState?.filter?.amount ?? [])[0].split(',')[0]),
                                takerMarket.currency
                              ),
                              max: asCurrency(
                                Number((urlState?.filter?.amount ?? [])[0].split(',')[1]),
                                takerMarket.currency
                              ),
                            })}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                handleFilter({ key: 'amount', value: undefined });
                                setShowEligibleInvoiceRangePicker(false);
                              }
                            }}
                          />
                        </div>
                      )
                    }
                  />
                  {/* Recurring Amount Rule Quick Action */}
                  {canEditRecurringRules && !isPreferred && (
                    <QuickActionCreateRule
                      ruleCategory="amount"
                      rule={recurringRule}
                      onClick={showRecurringRulesModal}
                    />
                  )}
                  {/* <Checkbox label="Custom Range" color="primary" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TABLE */}
        <div className="relative flex w-full flex-col overflow-hidden">
          <QueryBoundaries
            ErrorComponent={() => (
              <NoDataState
                icon={<TechnicalDifficultyIcon className="h-24 w-24" />}
                title={t('invoices.errorLoadingInvoices')}
                message={t('invoices.errorLoadingInvoicesDescription')}
                action={
                  <Button size="sm" onClick={() => window.location.reload()}>
                    {t('somethingsWrong.cta1Heading')}
                  </Button>
                }
              />
            )}
            LoadingComponent={() => (
              <NoDataState
                icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
                message={t('invoices.fetchingEligibleInvoices')}
              />
            )}
          >
            <EligibleInvoiceTable
              handleInvoiceSelect={handleInvoiceSelect}
              handleSearch={handleSearch}
              handleSort={handleSort}
              options={urlState}
              pagination={{
                currentPage: urlState.page ?? 1,
                onNextChange: handleNextChange,
                onPageSizeChange: (e) => handlePageSizeChange(e),
                onPrevChange: handlePrevChange,
                pageSize: urlState.limit ?? 100,
              }}
              selectedInvoiceDispatch={selectedInvoiceDispatch}
              selectedInvoiceState={selectedInvoiceState}
              sort={sort}
              takerMarket={takerMarket}
              toggleBulkInvoiceInclusionExclusion={toggleBulkInvoiceInclusionExclusion}
              toggleInvoiceInclusionExclusion={toggleInvoiceInclusionExclusion}
              showRecurringRulesModal={showRecurringRulesModal}
            />
          </QueryBoundaries>
        </div>
      </div>
    </>
  );
};

export default EligibleInvoices;
