import { Outlet, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TakerMarket } from '@/data/useTakerMarkets';
import useFeature from '@/lib/features';
import LinkTabs from './LinkTabs';
import { MarketLayoutOutletContext } from './MarketLayout';

export interface MarketHistoryLayoutOutletContext {
  readonly takerMarket?: TakerMarket;
  readonly urlParams?: {
    marketId: string;
    takerId: number;
  };
}
export function useMarketHistoryLayoutOutletContext() {
  return useOutletContext<MarketHistoryLayoutOutletContext>();
}

const MarketHistoryLayout = () => {
  const { t } = useTranslation();
  const marketContext = useOutletContext<MarketLayoutOutletContext>();
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');

  const outlet = (
    <Outlet
      {...(marketContext && {
        context: {
          takerMarket: marketContext.takerMarket,
          urlParams: {
            marketId: marketContext.urlParams.marketId,
            takerId: marketContext.urlParams.takerId,
          },
        } satisfies MarketHistoryLayoutOutletContext,
      })}
    />
  );

  if (!enableAwardsEnhancement) {
    return outlet;
  }

  return (
    <div>
      <div className="rounded-md bg-white shadow-md">
        <div className="px-8 pt-4">
          <LinkTabs
            items={[
              {
                key: 'approved',
                label: t('core.approved'),
                link: 'approved',
                matcher: 'approved',
              },
              {
                key: 'pending',
                label: t('core.pendingApproval'),
                link: 'pending',
                matcher: 'pending',
              },
            ]}
            variant="secondary"
          />
        </div>
        {outlet}
      </div>
    </div>
  );
};

export default MarketHistoryLayout;
