import { useCallback } from 'react';
import { GetMeQuery } from '@/generated/gql/graphql';
import useUserDetails from '@/data/useUserDetails';
import { isProd } from './env';

const useNewExperienceEnabled = () => {
  const isProdEnv = isProd();
  const { data: newExperienceEnabled } = useUserDetails(
    useCallback((user: GetMeQuery) => user.me?.account?.newExperienceEnabled, [])
  );

  // only check NSE account config in PROD because account configs are minimal
  // in lower environments and we don't want to disable which experience is available
  // for certain use cases in lower environments
  if (isProdEnv) {
    // if user does not have an account config, default new experience enabled to true
    return newExperienceEnabled ?? true;
  }

  return false;
};

export default useNewExperienceEnabled;
