import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFeature from '@/lib/features';
import LinkTabs from './LinkTabs';

const ConsolidatedHistoryLayout = () => {
  const { t } = useTranslation();
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');

  if (!enableAwardsEnhancement) {
    return <Outlet />;
  }

  return (
    <div>
      <h1 className="mt-0 text-4xl">{t('taker.consolidatedHistory.general')}</h1>
      <div className="rounded-md bg-white shadow-md">
        <div className="px-8 pt-4">
          <LinkTabs
            items={[
              {
                key: 'approved',
                label: t('core.approved'),
                link: 'approved',
                matcher: 'approved',
              },
              {
                key: 'pending',
                label: t('core.pendingApproval'),
                link: 'pending',
                matcher: 'pending',
              },
            ]}
            variant="secondary"
          />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default ConsolidatedHistoryLayout;
