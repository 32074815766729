import { Trans, useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';

export interface TranslationBlurMarketStatProps {
  takerMarkets: TakerMarket[];
  i18nKey: string;
  values?: Record<string, string>;
}

const TranslationBlurMarketStat = ({ takerMarkets, i18nKey, values }: TranslationBlurMarketStatProps) => {
  const { listenToMarketStats } = useServerSideEventListeners();
  const { t } = useTranslation();

  if (takerMarkets.length === 0) {
    return null;
  }

  const listeningForMarketStat = listenToMarketStats(
    takerMarkets.map(({ marketUuid, takerDivisionId }) => ({
      marketUuid,
      takerId: takerDivisionId,
    }))
  );

  const style = (
    <span className={cn({ 'blur-sm': listeningForMarketStat })}>
      {/* NOTE: This text is a placeholder and will be swapped out with value in json file tag */}
      $0.00
    </span>
  );

  return <Trans i18nKey={i18nKey} t={t} components={{ style }} values={values} />;
};

export default TranslationBlurMarketStat;
