import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

const useSubmitPreferredRenewalRequest = () => {
  return useMutation({
    mutationKey: ['submitPreferredRenewalRequest'],
    mutationFn: async ({ offerUuid }: { offerUuid: string }) => {
      return apiClient
        .post(
          `api/supplier-experience/offers/preferred-term/${offerUuid}/request-renewal`,
          undefined satisfies paths['/offers/preferred-term/{uuid}/request-renewal']['post']['requestBody']
        )
        .json<paths['/offers/preferred-term/{uuid}/request-renewal']['post']['responses']['204']['content']>();
    },
  });
};

export default useSubmitPreferredRenewalRequest;
