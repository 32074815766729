import { useQueries, useQuery } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import useEnableRecurringRules from '@/features/recurringRules/utils/useEnableRecurringRules';
import apiClient from '@/lib/apiClient';
import useFeature, { Features } from '@/lib/features';
import { SEAInvoiceRulesUpsertCriteriaBody } from './useCreateRecurringRule';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

export interface RecurringRuleData {
  id: string;
  marketId: number;
  takerId: number;
  gteDpe?: number;
  lteDpe?: number;
  fromDueDate?: string;
  toDueDate?: string;
  gteInvoiceAmount?: number;
  lteInvoiceAmount?: number;
  excludedVoucherIds?: string[];
}

export type RecurringRuleCategory = 'dpe' | 'invoiceId' | 'amount' | 'dueDate';

export type RecurringRuleCriteria = Exclude<keyof RecurringRuleData, 'id' | 'marketId' | 'takerId'>;

interface FormattedRecurringRule {
  amount?: {
    gteInvoiceAmount?: RecurringRuleData['gteInvoiceAmount'];
    lteInvoiceAmount?: RecurringRuleData['lteInvoiceAmount'];
  };
  dpe?: {
    gteDpe?: RecurringRuleData['gteDpe'];
    lteDpe?: RecurringRuleData['lteDpe'];
  };
  dueDate?: {
    fromDueDate?: RecurringRuleData['fromDueDate'];
    toDueDate?: RecurringRuleData['toDueDate'];
  };
  excludedVoucherIds?: RecurringRuleData['excludedVoucherIds'];
}

export interface RecurringRule extends FormattedRecurringRule {
  id: RecurringRuleData['id'];
  count: number;
  currency: TakerMarket['currency'];
  makerOrganizationUuid: TakerMarket['makerOrganizationUuid'];
  marketId: RecurringRuleData['marketId'];
  marketUuid: TakerMarket['marketUuid'];
  takerId: RecurringRuleData['takerId'];
  takerUuid: TakerMarket['takerDivisionUuid'];
}

export interface TakerMarketWithRecurringRule {
  rule: RecurringRule;
  takerMarket: TakerMarket;
}

type SEASupplierMarketQueryOptions = Pick<SEAInvoiceRulesRequestBody, 'supplierMarkets'>;

export type SEAInvoiceRulesResponse =
  paths['/invoice-rules']['post']['responses']['200']['content']['application/json'];

type SEAInvoiceRulesRequestBody = paths['/invoice-rules']['post']['requestBody']['content']['application/json'];

export type SEAInvoiceRules = SEAInvoiceRulesResponse['data'];

type SEAInvoiceRuleCriteria = SEAInvoiceRules[0]['rules'][0]['criteria'];

/**
 * formats recurring rule data for frontend use
 * input = { gteDpe: 8, lteDpe: 24, lteInvoiceAmount: 1000 }
 * output = {
 *   dpe: { gteDpe: 8, lteDpe: 24 },
 *   amount: { lteInvoiceAmount: 1000 }
 * }
 */
export const formatAsRecurringRule = (rule?: RecurringRuleData): FormattedRecurringRule | undefined => {
  if (!rule) {
    return;
  }

  const formattedRecurringRule: FormattedRecurringRule = {};

  if (rule.gteDpe || rule.lteDpe) {
    formattedRecurringRule.dpe = {
      ...(rule.gteDpe ? { gteDpe: rule.gteDpe } : {}),
      ...(rule.lteDpe ? { lteDpe: rule.lteDpe } : {}),
    };
  }

  if (rule.fromDueDate || rule.toDueDate) {
    formattedRecurringRule.dueDate = {
      ...(rule.fromDueDate ? { fromDueDate: rule.fromDueDate } : {}),
      ...(rule.toDueDate ? { toDueDate: rule.toDueDate } : {}),
    };
  }

  if (rule.gteInvoiceAmount || rule.lteInvoiceAmount) {
    formattedRecurringRule.amount = {
      ...(rule.gteInvoiceAmount ? { gteInvoiceAmount: rule.gteInvoiceAmount } : {}),
      ...(rule.lteInvoiceAmount ? { lteInvoiceAmount: rule.lteInvoiceAmount } : {}),
    };
  }

  if (rule.excludedVoucherIds) {
    formattedRecurringRule.excludedVoucherIds = rule.excludedVoucherIds;
  }

  return formattedRecurringRule;
};

const fetchRecurringRules = async (
  makerOrganizationUuid: string,
  takerMarkets: TakerMarket[]
): Promise<TakerMarketWithRecurringRule[]> => {
  const data = await apiClient
    .get(`api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules`)
    .json<RecurringRuleData[]>();

  return data.reduce<TakerMarketWithRecurringRule[]>((formattedRecurringRules, rule) => {
    const takerMarket = takerMarkets.find(
      (takerMarket) =>
        takerMarket.makerOrganizationUuid === makerOrganizationUuid &&
        takerMarket.takerDivisionId === rule.takerId &&
        takerMarket.legacyMarketId === rule.marketId
    );

    if (takerMarket) {
      const formattedRecurringRule = formatAsRecurringRule(rule);

      formattedRecurringRules.push({
        rule: {
          id: rule.id,
          count: Object.keys(formattedRecurringRule ?? {}).length,
          currency: takerMarket.currency,
          makerOrganizationUuid,
          marketId: rule.marketId,
          marketUuid: takerMarket.marketUuid,
          takerId: rule.takerId,
          takerUuid: takerMarket.takerDivisionUuid,
          ...formattedRecurringRule,
        },
        takerMarket,
      });
    }

    return formattedRecurringRules;
  }, []);
};

/**
 * formats recurring rule data for frontend use
 * input = { dpe: {gte: 8, lte: 24}, invoiceAmount: {lte: 1000} }
 * output = {
 *   dpe: { gteDpe: 8, lteDpe: 24 },
 *   amount: { lteInvoiceAmount: 1000 }
 * }
 */
export const formatSEAInvoiceRuleAsRecurringRule = (
  rule?: SEAInvoiceRuleCriteria | SEAInvoiceRulesUpsertCriteriaBody
): FormattedRecurringRule | undefined => {
  if (!rule) {
    return;
  }

  const formattedRecurringRule: FormattedRecurringRule = {};

  if (rule?.dpe?.gte || rule?.dpe?.lte) {
    formattedRecurringRule.dpe = {
      ...(rule?.dpe?.gte ? { gteDpe: rule?.dpe?.gte } : {}),
      ...(rule?.dpe?.lte ? { lteDpe: rule?.dpe?.lte } : {}),
    };
  }

  if (rule?.dueDate?.from || rule?.dueDate?.to) {
    formattedRecurringRule.dueDate = {
      ...(rule?.dueDate?.from ? { fromDueDate: rule?.dueDate?.from } : {}),
      ...(rule?.dueDate?.to ? { toDueDate: rule?.dueDate?.to } : {}),
    };
  }

  if (rule?.invoiceAmount?.gte || rule?.invoiceAmount?.lte) {
    formattedRecurringRule.amount = {
      ...(rule?.invoiceAmount?.gte ? { gteInvoiceAmount: rule?.invoiceAmount?.gte } : {}),
      ...(rule?.invoiceAmount?.lte ? { lteInvoiceAmount: rule?.invoiceAmount?.lte } : {}),
    };
  }

  if (rule?.voucherIds?.exclude) {
    formattedRecurringRule.excludedVoucherIds = rule?.voucherIds?.exclude;
  }

  return formattedRecurringRule;
};

const formatSEAInvoiceRules = (data: SEAInvoiceRules, takerMarkets: TakerMarket[]): TakerMarketWithRecurringRule[] => {
  return data.reduce<TakerMarketWithRecurringRule[]>((formattedRecurringRules, ruleGroup) => {
    const rule = ruleGroup.rules[0]; // It is expected that each rule group will only have one rule when queried with groupingKey SUPPLIER_MARKET

    const takerMarket = takerMarkets.find(
      (takerMarket) =>
        takerMarket.makerOrganizationUuid === rule.supplierMarket.makerOrganizationUuid &&
        takerMarket.takerDivisionUuid === rule.supplierMarket.supplierDivisionUuid &&
        takerMarket.marketUuid === rule.supplierMarket.marketUuid
    );

    if (takerMarket) {
      const formattedRecurringRule = formatSEAInvoiceRuleAsRecurringRule(rule.criteria);

      formattedRecurringRules.push({
        rule: {
          id: ruleGroup.uuid, // runtime uuid of the rule group
          count: Object.keys(formattedRecurringRule ?? {}).length,
          currency: takerMarket.currency,
          makerOrganizationUuid: rule.supplierMarket.makerOrganizationUuid,
          marketId: takerMarket.legacyMarketId,
          marketUuid: rule.supplierMarket.marketUuid,
          takerId: takerMarket.takerDivisionId,
          takerUuid: rule.supplierMarket.supplierDivisionUuid,
          ...formattedRecurringRule,
        },
        takerMarket,
      });
    }

    return formattedRecurringRules;
  }, []);
};

export const fetchSEAInvoiceRules = async (
  takerMarkets: TakerMarket[],
  options?: SEASupplierMarketQueryOptions
): Promise<TakerMarketWithRecurringRule[]> => {
  const supplierMarketsFilter = (options && 'supplierMarkets' in options && options.supplierMarkets) || undefined;

  const seaRules = await apiClient
    .post(`api/supplier-experience/invoice-rules`, {
      json: {
        groupingKey: 'SUPPLIER_MARKET',
        ...(supplierMarketsFilter
          ? {
              supplierMarkets: supplierMarketsFilter,
            }
          : {}),
      } satisfies SEAInvoiceRulesRequestBody,
    })
    .json<SEAInvoiceRulesResponse>();

  return seaRules.data ? formatSEAInvoiceRules(seaRules.data, takerMarkets) : [];
};

const useRecurringRules = (options?: SEASupplierMarketQueryOptions): TakerMarketWithRecurringRule[] => {
  const [enableSEARulesApi] = useFeature(Features['enterprise-ui_enableSeaRules']);
  const { data: takerMarkets = [] } = useTakerMarkets();
  const enableRecurringRules = useEnableRecurringRules();

  const uniqueMakerOrganizationUuids = [
    ...new Set(takerMarkets.map((takerMarket) => takerMarket.makerOrganizationUuid)),
  ];

  const seaRulesQuery = useQuery({
    queryKey: options?.supplierMarkets ? ['recurring-rules', ...options.supplierMarkets] : ['recurring-rules'],
    queryFn: () => fetchSEAInvoiceRules(takerMarkets, options), // need taker markets to enrich rule data
    enabled: enableSEARulesApi && enableRecurringRules && takerMarkets.length > 0,
  });

  const recurringRulesQueries = useQueries({
    queries:
      uniqueMakerOrganizationUuids && uniqueMakerOrganizationUuids.length > 0
        ? uniqueMakerOrganizationUuids.map((makerOrganizationUuid) => {
            return {
              queryKey: ['recurring-rules', makerOrganizationUuid],
              queryFn: () => fetchRecurringRules(makerOrganizationUuid, takerMarkets),
              enabled: !enableSEARulesApi && enableRecurringRules,
            };
          })
        : [],
  });

  if (enableSEARulesApi) {
    // we are not making API call if there are no taker markets. so returning empty array
    if (takerMarkets.length === 0) {
      return [];
    }

    return seaRulesQuery.isSuccess ? seaRulesQuery.data : [];
  }

  return recurringRulesQueries.filter((rule) => rule.isSuccess).flatMap((rule) => rule.data);
};

export default useRecurringRules;
