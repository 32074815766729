import { useTranslation } from 'react-i18next';
import { Button, Paper } from '@c2fo/liquidity';
import successmountain from '@/assets/successmountain.svg?url';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';
import getIsBeta from './utils/getIsBeta';

const NoActiveRules = ({
  showInformationRequest,
  showRecurringRulesModal,
  loadingInformationRequest,
  disableInformationRequest,
}: {
  showInformationRequest: () => void;
  showRecurringRulesModal: () => void;
  loadingInformationRequest: boolean;
  disableInformationRequest: boolean;
}) => {
  const isBeta = getIsBeta();
  const { t } = useTranslation();
  const { track } = useReporting();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);

  const onClick = () => {
    showRecurringRulesModal();
    track('recurring-rules::opened', {
      referrer: 'rules-manager',
      source: 'no-active-rules',
    });
  };

  return (
    <Paper elevation={2}>
      <div className="m-auto flex max-w-[90rem] flex-col-reverse items-center gap-10 p-8 lg:flex-row lg:gap-20 lg:p-20">
        <div className="flex flex-col gap-6">
          <h3 className="m-0 text-2xl font-normal text-text-primary sm:text-3xl">
            {isBeta ? t('recurringRules.noActiveRulesTitleBeta') : t('recurringRules.noActiveRulesTitle')}
          </h3>
          <p className="m-0">
            {isBeta ? t('recurringRules.noActiveRulesMessageBeta') : t('recurringRules.noActiveRulesMessage')}
          </p>
          <p className="m-0">
            {isBeta ? t('recurringRules.noActiveRulesCtaMessageBeta') : t('recurringRules.noActiveRulesCtaMessage')}
          </p>
          {isBeta && (
            <ul className="mb-2 ml-6 list-disc">
              <li>
                <span className="font-bold">{t('recurringRules.noActiveRulesCtaMessageBetaListItem1Title')}</span>{' '}
                {t('recurringRules.noActiveRulesCtaMessageBetaListItem1Message')}
              </li>
              <li>
                <span className="font-bold">{t('recurringRules.noActiveRulesCtaMessageBetaListItem2Title')}</span>{' '}
                {t('recurringRules.noActiveRulesCtaMessageBetaListItem2Message')}
              </li>
              <li>
                <span className="font-bold">{t('recurringRules.noActiveRulesCtaMessageBetaListItem3Title')}</span>{' '}
                {t('recurringRules.noActiveRulesCtaMessageBetaListItem3Message')}
              </li>
            </ul>
          )}
          {isBeta && !canEditRecurringRules ? (
            <Button
              className="w-full sm:w-fit"
              onClick={showInformationRequest}
              variant="primary"
              loading={loadingInformationRequest}
              disabled={disableInformationRequest}
            >
              {t('recurringRules.noActiveRulesCtaBeta')}
            </Button>
          ) : (
            <Button
              className="w-full sm:w-fit"
              onClick={isBeta ? showInformationRequest : onClick}
              variant="primary"
              loading={isBeta ? loadingInformationRequest : false}
              disabled={isBeta ? disableInformationRequest : !canEditRecurringRules}
            >
              {isBeta ? t('recurringRules.noActiveRulesCtaBeta') : t('recurringRules.createRecurringRule')}
            </Button>
          )}
        </div>
        <div className="flex w-full justify-center self-center">
          <img src={successmountain} alt="mountain" className="max-w-64 lg:max-w-80" />
        </div>
      </div>
    </Paper>
  );
};

export default NoActiveRules;
