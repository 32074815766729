import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

const useSubmitInformationRequestInvoiceRules = () => {
  return useMutation({
    mutationKey: ['submitInformationRequestInvoiceRules'],
    mutationFn: async () => {
      return apiClient
        .post(
          'api/supplier-experience/information-request/invoice-rules',
          undefined satisfies paths['/information-request/invoice-rules']['post']['requestBody']
        )
        .json<paths['/information-request/invoice-rules']['post']['responses']['204']['content']>();
    },
  });
};

export default useSubmitInformationRequestInvoiceRules;
