import { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Checkbox,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Popover,
  Spinner,
  Switch,
  useSnackbar,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon, TrashCanIcon } from '@c2fo/liquidity/icons';
import emptystatements from '@/assets/emptystatementsgrey.svg?url';
import invoices from '@/assets/invoicesgrey.svg?url';
import ExcludedInvoicesAlert from '@/components/ExcludedInvoicesAlert';
import { useInvoiceLayoutOutletContext } from '@/components/InvoiceLayout';
import NoDataState from '@/components/NoDataState';
import QueryBoundaries from '@/components/QueryBoundaries';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  TableSortArrow,
  TableDisplay,
} from '@/components/Table';
import useEligibleInvoices, {
  EligibleInvoice,
  EligibleInvoiceFetchFilters,
  EligibleInvoiceFetchOptions,
  EligibleInvoiceOrder,
} from '@/data/useEligibleInvoices';
import { useEligibleInvoiceStats, useFilteredEligibleInvoiceStats } from '@/data/useEligibleInvoiceStats';
import { useExcludeFutureInvoices } from '@/data/useExcludeFutureInvoices';
import { TakerMarket } from '@/data/useTakerMarkets';
import useUpdateOffer, { UpdateOfferVariables } from '@/data/useUpdateOffer';
import useGetRecurringRulesForTakerMarkets from '@/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import useFeature from '@/lib/features';
import { EligibleInvoiceTableSort } from '@/pages/EligibleInvoices';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useInvoiceDownloadUrl from '@/utils/useInvoiceDownloadUrl';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import useUserHasIndiaDivisions from '@/utils/useUserHasIndiaDivisions';
import BulkInvoiceActionModal from '../aggregateStatsBanner/components/bulkInvoiceActionModal';
import DeleteRuleModal from '../recurringRules/components/DeleteRuleModal';
import QuickActionCreateRule from '../recurringRules/components/QuickActionCreateRule';
import RuleChips from '../recurringRules/components/RuleChips';
import RuleExcludedInvoicesAlert from '../recurringRules/components/RuleExcludedInvoicesAlert';
import { RecurringRuleFormValues } from '../recurringRules/RecurringRulesForm';
import useEnableRecurringRules from '../recurringRules/utils/useEnableRecurringRules';
import EligibleInvoiceActionsDropdown from './components/EligibleInvoiceActionsDropdown';
import InvoiceAdjustmentPopover from './components/InvoiceAdjustmentPopover';
import PreferredOfferEditInvoiceAlert from './components/PreferredOfferEditInvoiceAlert';
import {
  SelectedInvoiceAction,
  SelectedInvoiceState,
  isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment,
} from './utils';

const FilteredEligibleInvoiceStatsCount = ({
  filter,
  takerMarket,
}: {
  takerMarket: TakerMarket;
  filter?: Partial<EligibleInvoiceFetchFilters>;
}) => {
  const { t } = useTranslation();
  const { asCurrency, asNumber } = useLocaleFormat();
  const {
    urlParams: { marketId, takerId },
  } = useInvoiceLayoutOutletContext();

  const { data: filteredEligibleInvoiceStats } = useFilteredEligibleInvoiceStats({
    filter,
    marketId,
    takerId,
  });

  return filter ? (
    <>
      <div className="hidden lg:block">&#8594;</div>
      <div className="flex items-center gap-1 text-center">
        <div>{t('core.filteredAR')}:</div>
        <div className="font-medium">
          {asCurrency(filteredEligibleInvoiceStats?.subTotal ?? 0, takerMarket.currency)}
        </div>
        <div className="font-medium lowercase">
          ({asNumber({ value: filteredEligibleInvoiceStats?.count ?? 0 })} {t('maker.invoices')})
        </div>
      </div>
    </>
  ) : null;
};

const EligibleInvoiceTable = ({
  handleInvoiceSelect,
  handleSearch,
  handleSort,
  options,
  pagination,
  selectedInvoiceDispatch,
  selectedInvoiceState,
  sort,
  takerMarket,
  toggleBulkInvoiceInclusionExclusion,
  toggleInvoiceInclusionExclusion,
  showRecurringRulesModal,
}: {
  handleInvoiceSelect: (invoice: EligibleInvoice) => void;
  handleSearch: (search: string) => void;
  handleSort: (key: EligibleInvoiceOrder) => void;
  options: Partial<EligibleInvoiceFetchOptions>;
  pagination: {
    currentPage: number;
    onNextChange: () => void;
    onPageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onPrevChange: () => void;
    pageSize: number;
  };
  selectedInvoiceDispatch: Dispatch<SelectedInvoiceAction>;
  selectedInvoiceState: SelectedInvoiceState;
  sort: EligibleInvoiceTableSort;
  takerMarket: TakerMarket;
  toggleBulkInvoiceInclusionExclusion: (action: 'include' | 'exclude') => void;
  toggleInvoiceInclusionExclusion: (action: 'include' | 'exclude') => void;
  showRecurringRulesModal: (defaultValues: Partial<RecurringRuleFormValues>) => void;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asCurrency, asNumber, asPercent } = useLocaleFormat();
  const hasIndiaDivisions = useUserHasIndiaDivisions();
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const {
    loader,
    urlParams: { marketId, takerId },
  } = useInvoiceLayoutOutletContext();
  const { data: eligibleInvoiceStats } = useEligibleInvoiceStats({
    takerId,
    marketId,
  });
  const { data: eligibleInvoices, isFetching } = useEligibleInvoices({
    marketId,
    takerId,
    options,
  });
  const { mutateAsync: toggleExcludeFutureInvoices, isLoading } = useUpdateOffer();
  const { mutateAsync: toggleExcludeFutureInvoicesSea, isLoading: isExcludingFutureInvoicesSea } =
    useExcludeFutureInvoices();
  const invoiceDownloadUrl = useInvoiceDownloadUrl({ filters: options?.filter, marketId, takerId, type: 'eligible' });
  const { loading } = loader;
  const { getRestrictions } = useRestrictions();
  const { canEditInvoices, canEditRecurringRules } = getRestrictions([takerMarket]);
  const enableRecurringRules = useEnableRecurringRules();
  const [enableSeaApi] = useFeature('enterprise-ui_enableSeaWrites');
  const disableBulkActions = selectedInvoiceState.selectedInvoiceCount > 0;
  const disableBulkDownload = disableBulkActions || (eligibleInvoices?.length ?? 0) === 0;
  const filterSelected = !!options.filter;
  const disableBulkInvoiceExclude =
    (eligibleInvoices?.length ?? 0) === 0 ||
    disableBulkActions ||
    takerMarket.eligibleInvoiceCount === 0 ||
    !canEditInvoices;
  const disableBulkInvoiceInclude =
    (eligibleInvoices?.length ?? 0) === 0 ||
    disableBulkActions ||
    takerMarket.takerExcludedInvoiceCount === 0 ||
    !canEditInvoices;
  const [bulkActionType, setBulkActionType] = useState<'include' | 'exclude' | null>(null);
  const takerMarketName = getTakerMarketName(takerMarket);
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const [recurringRule] = getRecurringRulesForTakerMarkets([takerMarket]);
  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

  const onExcludeAllFutureInvoicesClick = async () => {
    if (enableSeaApi) {
      return await toggleExcludeFutureInvoicesSea(
        {
          makerOrganizationUuid: takerMarket.makerOrganizationUuid,
          offerUuid: takerMarket.offerConfig.uuid!,
          marketUuid: takerMarket.marketUuid,
          supplierDivisionUuid: takerMarket.takerDivisionUuid,
          takerMarketGroupType: getMarketType(takerMarket),
        },
        {
          onSuccess: () => {
            showSnackbar({
              message: t('offer.invoicesUpdated'),
            });
            track('invoice-exclude-future::submitted');
          },
          onError: () => showSnackbar({ message: t('offer.invoicesUpdateError') }),
        }
      );
    }

    const submitValues = {
      marketId: takerMarket.offerConfig.marketId,
      marketUuid: takerMarket.offerConfig.marketUuid,
      offerConfig: {
        ...takerMarket.offerConfig,
        /**
         * for benchmark submits, set maxApr to 99
         * this prevents updating rate to anything other than 99%
         */
        ...(getMarketType(takerMarket) === 'BENCHMARK' ? { maxApr: 99 } : {}),
        exclusionSettings: {
          ...takerMarket.offerConfig.exclusionSettings,
          excludeNewInvoices: !takerMarket.offerConfig.exclusionSettings?.excludeNewInvoices,
        },
      },
      takerId: takerMarket.offerConfig.divisionId,
    } satisfies UpdateOfferVariables;

    /**
     * Note: This ultimately needs to be uplifted to use public api / shim.
     * The reason it is not is because the legacy API updates the invoice table after setting exclusion to true,
     * in a way we do not yet do in shim. To keep moving and release other parts of shim sooner,
     * we can continue using the node api for this one specific way of changing taker config.
     * Potentially we can also use platform-api https://honeycomb.jira.com/browse/MM-14833
     */
    await toggleExcludeFutureInvoices(submitValues, {
      onSuccess: () => {
        showSnackbar({
          message: t('offer.invoicesUpdated'),
        });
        track('invoice-exclude-future::submitted');
      },
      onError: () => showSnackbar({ message: t('offer.invoicesUpdateError') }),
    });
  };

  const showRuleExcludedInvoicesAlert = enableRecurringRules && !!recurringRule;
  const showEligibleExcludedInvoicesAlert =
    !showRuleExcludedInvoicesAlert && takerMarket.takerExcludedInvoiceAmount > 0;
  const showExcludingFutureInvoicesAlert = takerMarket.offerConfig.exclusionSettings.excludeNewInvoices;

  return (
    <>
      {enableRecurringRules && !!recurringRule && (
        <DeleteRuleModal
          onClose={() => {
            setOpenDeleteRuleModal(false);
            track('recurring-rules::bulk-delete::division', {
              makerOrganizationUuid: recurringRule.makerOrganizationUuid,
              referrer: 'division-details',
              takerMarkets: [{ marketId: recurringRule.marketId, takerId: recurringRule.takerId }],
            });
          }}
          open={openDeleteRuleModal}
          takerMarketWithRule={{
            rule: recurringRule,
            takerMarket,
          }}
        />
      )}
      {/* edit invoice action toolbar */}
      {selectedInvoiceState.selectedInvoiceCount > 0 && (
        <div className="fixed bottom-6 z-10 flex items-center rounded-md bg-secondary-500 p-4 text-center text-white shadow-lg left-center-48">
          <div className="border-r pr-4">
            <div className="font-medium">
              {`${asNumber({ value: selectedInvoiceState.selectedInvoiceCount })} ${
                selectedInvoiceState.selectedInvoiceCount === 1 ? t('core.invoiceSelected') : t('core.invoicesSelected')
              }`}
            </div>
            <div>{asCurrency(selectedInvoiceState.selectedInvoiceAmount, takerMarket.currency)}</div>
          </div>
          <div className="mt-4 flex gap-2 pl-4 lg:mt-0">
            <Button
              variant="primary"
              inverse
              type="button"
              size="sm"
              disabled={!selectedInvoiceState.enableIncludeButton || loading}
              onClick={() => toggleInvoiceInclusionExclusion('include')}
            >
              {t('core.include')}
            </Button>
            <Button
              variant="primary"
              inverse
              type="button"
              size="sm"
              disabled={!selectedInvoiceState.enableExcludeButton || loading}
              onClick={() => toggleInvoiceInclusionExclusion('exclude')}
            >
              {t('core.exclude')}
            </Button>
            <Button
              variant="primary"
              type="button"
              size="sm"
              onClick={() =>
                selectedInvoiceDispatch({
                  type: 'selectAll',
                  payload: { invoices: eligibleInvoices ?? [] },
                })
              }
              disabled={selectedInvoiceState.isBulk || loading}
            >
              {t('core.selectPage')}
            </Button>
            <Button
              variant="primary"
              type="button"
              size="sm"
              onClick={() => selectedInvoiceDispatch({ type: 'unselectAll' })}
              disabled={loading}
              className="-ml-2"
            >
              {t('core.unselectPage')}
            </Button>
          </div>
        </div>
      )}
      <PreferredOfferEditInvoiceAlert takerMarket={takerMarket} />
      <div className="flex flex-col items-center justify-between px-8 pb-4 pt-6 sm:flex-row">
        {/* total ar */}
        <div className="flex items-center gap-1 text-center">
          <div className={cn('flex gap-1', { 'text-text-disabled': options.filter })}>
            <div>{t('core.totalAR')}:</div>
            <div className="font-medium">{asCurrency(eligibleInvoiceStats?.subTotal ?? 0, takerMarket.currency)}</div>
            <div className="font-medium lowercase">
              ({asNumber({ value: eligibleInvoiceStats?.count ?? 0 })} {t('maker.invoices')})
            </div>
          </div>
          <QueryBoundaries>
            <FilteredEligibleInvoiceStatsCount filter={options.filter} takerMarket={takerMarket} />
          </QueryBoundaries>
        </div>
        <EligibleInvoiceActionsDropdown takerMarket={takerMarket} />
      </div>
      {/* search / download bar */}
      <div className="flex flex-col justify-between gap-2 space-y-6 border-b px-8 pb-6 lg:flex-row lg:items-center lg:space-y-0">
        <div className="flex gap-2">
          {canEditRecurringRules && !isPreferred && (
            <div className="mr-2 flex">
              <QuickActionCreateRule
                ruleCategory="invoiceId"
                rule={recurringRule}
                onClick={showRecurringRulesModal}
                condensed
              />
            </div>
          )}
          <input
            aria-label={t('taker.invoices.eligible.searchInputPlaceholder')}
            className="w-72 rounded-md border-0 px-3 py-2 outline-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600"
            placeholder={t('taker.invoices.eligible.searchInputPlaceholder')}
            type="text"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch(searchInput);
              }
            }}
          />
          <Button variant="secondary" size="sm" onClick={() => handleSearch(searchInput)}>
            {t('core.search')}
          </Button>
        </div>
        <Switch
          accessibilityLabel={t('taker.excludeFutureInvoices')}
          checked={takerMarket.offerConfig.exclusionSettings?.excludeNewInvoices ?? false}
          disabled={!canEditInvoices || !takerMarket.offerConfig.id || !takerMarket.offerConfig.isEnabled}
          label={t('taker.excludeFutureInvoices')}
          onCheckedChange={onExcludeAllFutureInvoicesClick}
        >
          {(checked) =>
            isLoading || isExcludingFutureInvoicesSea ? (
              <Spinner className={cn(checked ? 'fill-primary-500' : 'fill-deepGray-100')} />
            ) : null
          }
        </Switch>
      </div>
      {/* recurring rules */}
      {enableRecurringRules && !!recurringRule && (
        <IntercomDataTarget target="rulesDivisionDetails" className="border-b px-8 py-4">
          <div className="flex items-center justify-between gap-6">
            <div className="text-nowrap">
              <Popover>
                <Popover.Trigger>
                  <span className="flex items-center gap-2">
                    <span className="font-medium">{t('recurringRules.title')}</span>
                    <InfoCircleIcon fill={colors.gray[600]} />
                  </span>
                </Popover.Trigger>
                <Popover.Content arrow side="top">
                  <div className="max-w-64 text-sm">{t('recurringRules.tooltip')}</div>
                </Popover.Content>
              </Popover>
            </div>
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <IconButton
                    icon={TrashCanIcon}
                    inverse
                    name={t('recurringRules.menu')}
                    size="sm"
                    variant="destructive"
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" avoidCollisions={false} collisionPadding={24}>
                  <DropdownMenuItem
                    disabled={!canEditRecurringRules}
                    {...(canEditRecurringRules && { onClick: () => setOpenDeleteRuleModal(true) })}
                  >
                    {t('recurringRules.deleteRules')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="mt-2 flex flex-wrap overflow-y-auto">
            <RuleChips recurringRule={recurringRule} onEdit={showRecurringRulesModal} readOnly={isPreferred} />
          </div>
        </IntercomDataTarget>
      )}
      {/* bulk invoice actions */}
      <div className="flex items-center border-b px-8 py-4">
        <div className="mr-6">
          <Popover>
            <Popover.Trigger>
              <span className="flex items-center gap-2">
                <span className="font-medium">{t('core.bulkInvoiceActions')}</span>
                <InfoCircleIcon fill={colors.gray[600]} />
              </span>
            </Popover.Trigger>
            <Popover.Content arrow side="right">
              <div className="w-64 space-y-2 text-sm">
                <div>{t('core.bulkInvoiceActionsDescription')}</div>
                <div>{t('core.bulkInvoiceAppliedSearchFilters')}</div>
              </div>
            </Popover.Content>
          </Popover>
        </div>
        <div className="flex h-10 gap-2">
          <Button disabled={disableBulkDownload} size="sm" variant="secondary">
            <a className="flex" href={invoiceDownloadUrl} target="_self">
              {t('core.download')}
            </a>
          </Button>
          <Button
            disabled={disableBulkInvoiceExclude}
            onClick={() => {
              setBulkActionType('exclude');
            }}
            size="sm"
            variant="secondary"
          >
            {t('core.exclude')}
          </Button>
          <Button
            disabled={disableBulkInvoiceInclude}
            onClick={() => {
              setBulkActionType('include');
            }}
            size="sm"
            variant="secondary"
          >
            {t('core.include')}
          </Button>
          <BulkInvoiceActionModal
            open={!!bulkActionType}
            onClose={() => setBulkActionType(null)}
            bulkActionType={bulkActionType}
            takerMarketName={takerMarketName}
            toggleBulkInvoiceInclusionExclusion={toggleBulkInvoiceInclusionExclusion}
            filterSelected={filterSelected}
          />
        </div>
      </div>
      {(showRuleExcludedInvoicesAlert || showEligibleExcludedInvoicesAlert || showExcludingFutureInvoicesAlert) && (
        <div className="my-4 space-y-2">
          {/* Alert shown when recurring rules are enabled and there are recurring rules */}
          {showRuleExcludedInvoicesAlert && (
            <IntercomDataTarget target="rulesDivisionDetailsBanner" className="px-8">
              <RuleExcludedInvoicesAlert
                takerMarkets={[takerMarket]}
                excludedAmount={takerMarket.takerExcludedInvoiceAmount}
                currency={takerMarket.currency}
              />
            </IntercomDataTarget>
          )}
          {/* Alert shown when there are not recurring rules and there are eligible excluded invoices */}
          {showEligibleExcludedInvoicesAlert && (
            <div className="px-8">
              <ExcludedInvoicesAlert
                currency={takerMarket.currency}
                excludedAmount={takerMarket.takerExcludedInvoiceAmount}
                takerMarkets={[takerMarket]}
              />
            </div>
          )}
          {/* Alert shown when excluding all future invoices */}
          {showExcludingFutureInvoicesAlert && (
            <div className="px-8">
              <Alert
                description={t('taker.excludeInvoicesWarning')}
                full
                type="warning"
                variant="outlined"
                {...(canEditInvoices && {
                  action: {
                    onClick: () => {
                      onExcludeAllFutureInvoicesClick();
                      track('invoice-exclude-future-cancel::clicked');
                    },
                    text: t('core.cancel'),
                  },
                })}
              />
            </div>
          )}
        </div>
      )}
      <div className="relative w-full overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <Checkbox
                  checked={selectedInvoiceState.isBulk}
                  color="primary"
                  onChange={() => {
                    const { isBulk } = selectedInvoiceState;

                    return isBulk
                      ? selectedInvoiceDispatch({ type: 'unselectAll' })
                      : selectedInvoiceDispatch({
                          type: 'selectAll',
                          payload: { invoices: eligibleInvoices ?? [] },
                        });
                  }}
                  disabled={!canEditInvoices}
                />
              </TableHead>
              <TableHead onClick={() => handleSort('takerExcluded')}>
                <TableSortArrow accessorKey="takerExcluded" sort={sort}>
                  {t('core.included')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('isInCashPool')}>
                <TableSortArrow accessorKey="isInCashPool" sort={sort}>
                  {t('core.clearing')}
                </TableSortArrow>
              </TableHead>
              <TableHead className="max-w-[224px]" onClick={() => handleSort('voucherId')}>
                <TableSortArrow accessorKey="voucherId" sort={sort}>
                  {t('core.invoiceId')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('dueDate')}>
                <TableSortArrow accessorKey="dueDate" sort={sort} textRight>
                  {hasIndiaDivisions ? t('core.tentativePaymentDate') : t('core.originalDueDate')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('daysPaidEarly')}>
                <TableSortArrow accessorKey="daysPaidEarly" sort={sort} textRight>
                  {t('core.dpe')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('amount')}>
                <TableSortArrow accessorKey="amount" sort={sort} textRight>
                  {t('core.invoiceAmount')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('minDiscount')}>
                <TableSortArrow accessorKey="minDiscount" sort={sort} textRight>
                  {t('core.discountWithPercent')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('earn')}>
                <TableSortArrow accessorKey="earn" sort={sort} textRight>
                  {t('core.discount')}
                </TableSortArrow>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody loading={isFetching}>
            {!eligibleInvoices || !eligibleInvoices.length ? (
              <TableDisplay colSpan={9}>
                <NoDataState
                  className="py-8"
                  {...(options.filter
                    ? {
                        icon: <img src={emptystatements} alt="emptystatements" className="h-28 w-28" />,
                        title: t('invoices.noMatchingInvoices'),
                        message: t('invoices.noMatchingEligibleInvoicesDescription'),
                      }
                    : {
                        icon: <img src={invoices} alt="invoices" className="h-28 w-28" />,
                        title: t('invoices.noAvailableInvoices'),
                        message: t('invoices.noAvailableEligibleInvoicesDescription'),
                      })}
                />
              </TableDisplay>
            ) : (
              eligibleInvoices?.map((eligibleInvoice) => (
                <TableRow key={eligibleInvoice.id}>
                  <TableCell>
                    <Checkbox
                      aria-label={eligibleInvoice.voucherId}
                      color="primary"
                      onChange={() => handleInvoiceSelect(eligibleInvoice)}
                      checked={
                        !isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment(eligibleInvoice) &&
                        (selectedInvoiceState.isBulk ||
                          selectedInvoiceState.selectedInvoices.some((invoice) => invoice.id === eligibleInvoice.id))
                      }
                      disabled={
                        !canEditInvoices || isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment(eligibleInvoice)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {!eligibleInvoice.takerExcluded && <span className="text-primary-500">{t('core.yes')}</span>}
                  </TableCell>
                  <TableCell>
                    {eligibleInvoice.isInCashPool && <span className="text-primary-500">{t('core.yes')}</span>}
                    {!eligibleInvoice.isInCashPool && <span className="text-red-500">{t('core.no')}</span>}
                  </TableCell>
                  <TableCell className="max-w-[224px] whitespace-pre-wrap break-words">
                    {eligibleInvoice.voucherId}
                  </TableCell>
                  <TableCell className="text-right">
                    {eligibleInvoice.dueDate && trimDateTimestamp(eligibleInvoice.dueDate)}
                  </TableCell>
                  <TableCell className="text-right">{eligibleInvoice.daysPaidEarly}</TableCell>
                  <TableCell className="text-right">
                    {eligibleInvoice.adjustmentAmount ? (
                      <InvoiceAdjustmentPopover
                        adjustmentAmount={eligibleInvoice.adjustmentAmount}
                        amount={eligibleInvoice.amount}
                        coversUnmatched={eligibleInvoice.coversUnmatched}
                        currency={eligibleInvoice.currency}
                        originalAmount={eligibleInvoice.originalAmount}
                      />
                    ) : (
                      asCurrency(eligibleInvoice.amount, eligibleInvoice.currency)
                    )}
                  </TableCell>
                  <TableCell
                    className={cn('text-right', {
                      'text-red-500': !eligibleInvoice.isInCashPool,
                    })}
                  >
                    {eligibleInvoice.minDiscount
                      ? asPercent({
                          value: eligibleInvoice.minDiscount / 100,
                          decimals: 2,
                        })
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={cn('text-right', {
                      'text-red-500': !eligibleInvoice.isInCashPool,
                    })}
                  >
                    {eligibleInvoice.earn ? asCurrency(eligibleInvoice.earn, eligibleInvoice.currency) : '-'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        className="mt-auto"
        disableNext={
          pagination.currentPage ===
            Math.ceil((eligibleInvoiceStats?.count ?? pagination.pageSize) / pagination.pageSize) ||
          (eligibleInvoices?.length ?? 0) < pagination.pageSize
        }
        disablePrev={pagination.currentPage === 1}
        onNextChange={() => pagination.onNextChange()}
        onPageSizeChange={(e) => pagination.onPageSizeChange(e)}
        onPrevChange={() => pagination.onPrevChange()}
        pageSize={pagination.pageSize}
      />
    </>
  );
};

export default EligibleInvoiceTable;
