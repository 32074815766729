import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import useEditInvoices from '@/data/useEditInvoices';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import TranslationBlurMarketStat from './TranslationBlurMarketStat';

export interface ExcludedInvoicesAlertProps {
  excludedAmount: number;
  takerMarkets: TakerMarket[];
  currency?: string;
  tab?: TakerMarketGroupType;
}

const ExcludedInvoicesAlert = ({ currency, excludedAmount, tab, takerMarkets }: ExcludedInvoicesAlertProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { asCurrency } = useLocaleFormat();
  const { includeInvoices } = useEditInvoices();
  const selectedCurrency = useSelectedCurrency();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRestrictions } = useRestrictions();
  const { canEditInvoices } = getRestrictions(takerMarkets);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { listenToMarketStats } = useServerSideEventListeners();

  if (takerMarkets.length === 0 || excludedAmount === 0) {
    return null;
  }

  // Only count taker markets with excluded invoices, taker markets with rules are already filtered out of the takerMarkets array
  const takerMarketsToReinclude = takerMarkets.filter((tm) => tm.takerExcludedInvoiceAmount > 0);

  const listeningForMarketStat = listenToMarketStats(
    takerMarketsToReinclude.map(({ marketUuid, takerDivisionId }) => ({
      marketUuid,
      takerId: takerDivisionId,
    }))
  );

  const handleIncludeInvoices = async () => {
    setLoading(true);

    await includeInvoices({ filters: { all: true }, takerMarkets: takerMarketsToReinclude });

    track('invoice-include-banner::confirmation-modal::clicked', {
      tab: tab ?? 'division', // reinclude came from a homepage tab or division page
      takerMarkets: takerMarketsToReinclude.map((tm) => ({
        takerId: tm.takerDivisionId,
        marketUuid: tm.marketUuid,
      })),
    });

    setLoading(false);
    handleCloseModal();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    track('invoice-include-banner::clicked', {
      tab: tab ?? 'division', // reinclude came from a homepage tab or division page
      takerMarkets: takerMarketsToReinclude.map((tm) => ({
        takerId: tm.takerDivisionId,
        marketUuid: tm.marketUuid,
      })),
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const alertDescription = (
    <TranslationBlurMarketStat
      takerMarkets={takerMarketsToReinclude}
      i18nKey="taker.reincludeInvoicesMessage"
      values={{ amount: asCurrency(excludedAmount, currency ?? selectedCurrency) }}
    />
  );

  return (
    <>
      <Modal disableOutsideClickClose={loading} onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>{t('core.includeEligibleInvoices')}</ModalTitleInverse>
        <ModalContent className="space-y-4">
          {takerMarketsToReinclude.length === 1 && (
            <div>
              {t('taker.includeInvoicesSingleDivisionWarning', {
                divisionName: getTakerMarketName(takerMarketsToReinclude[0]),
              })}
            </div>
          )}
          {takerMarketsToReinclude.length > 1 && (
            <div>{t('taker.includeInvoicesMultipleDivisionsWarning', { count: takerMarketsToReinclude.length })}</div>
          )}
          <div>{t('taker.includeInvoicesConfirmation')}</div>
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.cancel')}
          </Button>
          <Button loading={loading} onClick={handleIncludeInvoices} variant="cta">
            {t('core.includeInvoices')}
          </Button>
        </ModalActions>
      </Modal>
      <Alert
        description={alertDescription}
        full
        type="warning"
        variant="outlined"
        {...(canEditInvoices && {
          action: {
            loading: loading || listeningForMarketStat,
            onClick: handleOpenModal,
            text: t('core.includeEligibleInvoices'),
          },
        })}
      />
    </>
  );
};

export default ExcludedInvoicesAlert;
