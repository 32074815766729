import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { PreferredOfferInput } from '@/generated/gql/graphql';
import useTakerMarkets, { OfferConfig, TakerMarket } from '@/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import useFeature, { Features } from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { DraftOfferRateInformation } from './useDraftOffer';

interface ExtendedPreferredOfferInput {
  draftId: string;
  setOfferConfigQueryData?: Partial<OfferConfig> & { disableAfterMarketClearsDate: string | null };
  rateInformation: DraftOfferRateInformation;
  supplierMarkets: { supplierDivisionUuid: string; marketUuid: string }[];
}

export const SUBMIT_PREFERRED_OFFER = graphql(`
  mutation SubmitPreferredOffer($offer: PreferredOfferInput!) {
    submitPreferredOffer(offer: $offer)
  }
`);

function submitPreferredOffer(draftId: string, marketUuid: string, supplierDivisionUuids: string[]) {
  const offer: PreferredOfferInput = {
    draftId,
    marketUuid,
    supplierDivisionUuids,
  };

  return gqlClient.request(SUBMIT_PREFERRED_OFFER, { offer });
}

function submitPreferredOfferSEA(draftId: string, rateInformation: DraftOfferRateInformation) {
  return apiClient.post(`api/supplier-experience/offers/draft/${draftId}`, {
    json: {
      offerType: 'preferred-term',
      rateInformation: {
        rateType: rateInformation.rateType,
        rate:
          rateInformation.rateType === 'APR' || rateInformation.rateType === 'DISC' ? rateInformation.rate : undefined,
        spread: rateInformation.rateType === 'REF' && 'spread' in rateInformation ? rateInformation.spread : undefined,
      },
    },
  });
}

const useSubmitPreferredOffer = () => {
  const [enableSEA] = useFeature(Features['enterprise-ui_enableSeaDraftOffers']);
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['submitPreferredOffer'],
    mutationFn: async ({ draftId, supplierMarkets, rateInformation }: ExtendedPreferredOfferInput) => {
      if (enableSEA) {
        return submitPreferredOfferSEA(draftId, rateInformation);
      }

      // For draft offers created in shim and accepted by shim, only one marketUuid is used
      // This will be removed once SEA is the only source of data for draft offers
      const marketUuid = supplierMarkets[0].marketUuid;

      const supplierDivisionUuids = supplierMarkets.map((sm) => sm.supplierDivisionUuid);

      return submitPreferredOffer(draftId, marketUuid, supplierDivisionUuids);
    },
    onSuccess: (_data, variables) => {
      const updatedSupplierMarkets = variables.supplierMarkets;
      const effectedTakersMarkets = takerMarkets?.filter((tm) => {
        return (
          updatedSupplierMarkets.find((draftSupplierMarket) => {
            return (
              tm.marketUuid === draftSupplierMarket.marketUuid &&
              tm.takerDivisionUuid === draftSupplierMarket.supplierDivisionUuid
            );
          }) !== undefined
        );
      });

      if (effectedTakersMarkets && effectedTakersMarkets.length) {
        effectedTakersMarkets.forEach((effectedTm) => {
          // update the cache with the returned data
          queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) => {
            return (prevTakerMarkets ?? []).map((prevTm) => {
              const isMatch =
                prevTm.marketUuid === effectedTm.marketUuid &&
                prevTm.takerDivisionUuid === effectedTm.takerDivisionUuid;

              if (!isMatch) {
                return prevTm;
              }

              let offerConfig: OfferConfig = {
                ...prevTm.offerConfig,
                maxApr: null,
                maxDiscount: null,
                isEnabled: true,
                offerType: 'PREFERRED_TERM',
              };

              if (variables.setOfferConfigQueryData) {
                offerConfig = {
                  ...offerConfig,
                  ...variables.setOfferConfigQueryData,
                };
              }

              return {
                ...prevTm,
                offerConfig,
                // Set the "Term End Date" before stats are updated
                ...(variables.setOfferConfigQueryData?.disableAfterMarketClearsDate && {
                  disableAfterMarketClearsDate: variables.setOfferConfigQueryData?.disableAfterMarketClearsDate,
                }),
              };
            });
          });

          // subscribe to market stats
          // this will refetch stats queries after we know stats have updated
          subscribeToMarketStats({
            marketUuid: effectedTm.marketUuid,
            takerId: effectedTm.takerDivisionId,
          });
        });
      }
    },
  });
};

export default useSubmitPreferredOffer;
