import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, cn, Modal as LiquidityModal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';

export interface ModalProps {
  children: ReactNode;
  open: boolean;
  title: ReactNode;
  cancelText?: string;
  className?: string;
  fullWidthButtons?: boolean;
  loading?: boolean;
  okButtonVariant?: 'cta' | 'primary';
  okButtonDisabled?: boolean;
  okLink?: string;
  okText?: string;
  onCancel?: () => void;
  onClose?: () => void;
  onOk?: () => void;
  // showCloseIcon?: boolean;
  subTitle?: string;
  // variant?: 'info' | 'primary';
  // customWidth?: string;
}

const Modal = ({
  cancelText,
  children,
  className,
  fullWidthButtons,
  loading,
  okButtonVariant = 'cta',
  okButtonDisabled,
  okLink,
  okText,
  onCancel,
  onClose,
  onOk,
  open,
  subTitle,
  title,
}: ModalProps) => {
  const { t } = useTranslation();

  return (
    <LiquidityModal onClose={onClose} open={open} size="sm" className={className}>
      <ModalTitleInverse>
        <span className="flex flex-col">
          <span>{title}</span>
          {subTitle && <span className="text-sm font-normal text-text-secondary">{subTitle}</span>}
        </span>
      </ModalTitleInverse>

      <ModalContent>{children}</ModalContent>

      <ModalActions>
        {onCancel && (
          <Button full={fullWidthButtons} className="shrink" onClick={onCancel} variant="secondary">
            {cancelText ?? t('core.cancel')}
          </Button>
        )}
        {okLink ? (
          <a className={cn('shrink', { 'w-full': fullWidthButtons })} href={okLink} onClick={onOk} target="_self">
            <Button className="shrink" full={fullWidthButtons} variant="primary">
              {okText ?? t('core.ok')}
            </Button>
          </a>
        ) : onOk ? (
          <Button
            className="shrink"
            full={fullWidthButtons}
            loading={loading}
            onClick={onOk}
            variant={okButtonVariant}
            disabled={okButtonDisabled}
          >
            {okText ?? t('core.ok')}
          </Button>
        ) : null}
      </ModalActions>
    </LiquidityModal>
  );
};

export default Modal;
