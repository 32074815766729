import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import useTakerMarkets, { OfferConfig, TakerMarket } from '@/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useDataDog from '@/reporting/DataDog';
import { useQualtrics } from '@/reporting/Qualtrics';

export const TOGGLE_OFFER = graphql(`
  mutation ToggleOffer($marketUuid: String!, $supplierDivisionUuid: String!) {
    toggleOffer(marketUuid: $marketUuid, supplierDivisionUuid: $supplierDivisionUuid) {
      enabled
    }
  }
`);

export interface ToggleOfferParams {
  supplierDivisionUuid: string;
  marketUuid: string;
  seaUuid?: string;
  frequency?: OfferConfig['frequency'];
}

const toggleOffer = ({
  enableSEApi,
  marketUuid,
  supplierDivisionUuid,
  seaUuid,
  frequency,
}: {
  enableSEApi: boolean;
  marketUuid: string;
  supplierDivisionUuid: string;
  seaUuid?: string;
  frequency?: OfferConfig['frequency'];
}) => {
  if (enableSEApi && seaUuid) {
    // if frequency is one time, delete instead of toggling because a one time offer cannot be toggled
    if (frequency === 'ONE_TIME') {
      return apiClient.delete(`api/supplier-experience/offers/${seaUuid}`);
    }

    return apiClient.patch(`api/supplier-experience/offers/${seaUuid}/toggle-status`);
  }

  const input = { marketUuid, supplierDivisionUuid };
  return gqlClient.request(TOGGLE_OFFER, input);
};

const useToggleOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { triggerNPSSurvey } = useQualtrics();
  const { addError } = useDataDog();
  const [enableSeaReads] = useFeature('enterprise-ui_enableSeaReads');
  const [enableSeaWrites] = useFeature('enterprise-ui_enableSeaWrites');
  const enableSEApi = enableSeaReads && enableSeaWrites;

  return useMutation({
    mutationKey: ['toggleOffer'],
    mutationFn: async ({ marketUuid, supplierDivisionUuid, seaUuid, frequency }: ToggleOfferParams) => {
      return toggleOffer({ enableSEApi, marketUuid, supplierDivisionUuid, seaUuid, frequency });
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => {
            const isTogglingOff = tmMeta.offerConfig.isEnabled === true; // config was true on existing tm data (before toggle called)
            if (tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid) {
              // At this time, only price discovery offers will reset an expiration date when toggled off
              const preemptivelyResetExpirationDate = isTogglingOff && tmMeta.offerConfig.type === 'PRICE_DISCOVERY';
              return {
                ...tm,
                offerConfig: {
                  ...tm.offerConfig,
                  isEnabled: !tmMeta.offerConfig.isEnabled,
                },
                disableAfterMarketClearsDate: preemptivelyResetExpirationDate ? null : tm.disableAfterMarketClearsDate,
              };
            }

            return tm;
          })
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }

      setTimeout(() => {
        const isEnabled = !tmMeta?.offerConfig.isEnabled;
        if (isEnabled) {
          triggerNPSSurvey();
        }
      }, 3000);
    },
    onError: (error, variables) => {
      addError('useToggleOffer', {
        error,
        variables,
        featureFlags: {
          enableSeaReads,
          enableSeaWrites,
        },
      });
    },
  });
};

export default useToggleOffer;
