import { ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactoryProvider, useSplitTreatments } from '@splitsoftware/splitio-react';
import { FullPageLoader } from '@/components/FullPageLoader';
import QueryBoundaries from '@/components/QueryBoundaries';
import useUserDetails from '@/data/useUserDetails';
import SomethingsWrong from '@/pages/SomethingsWrong';

/**
 * feature flags namespace "enterprise-ui_"
 */
export const Features = {
  'enterprise-ui_enableSetExpirationDate': 'enterprise-ui_enableSetExpirationDate',
  'enterprise-ui_enableRecurringRules': 'enterprise-ui_enableRecurringRules',
  'enterprise-ui_enableRecurringRulesForAccounts': 'enterprise-ui_enableRecurringRulesForAccounts',
  'enterprise-ui_fedRateBanner': 'enterprise-ui_fedRateBanner',
  'enterprise-ui_enableSeaReads': 'enterprise-ui_enableSeaReads',
  'enterprise-ui_enableSeaWrites': 'enterprise-ui_enableSeaWrites',
  'enterprise-ui_enableSeaDraftOffers': 'enterprise-ui_enableSeaDraftOffers',
  'enterprise-ui_enablePendingAndFutureAwardsEnhancement': 'enterprise-ui_enablePendingAndFutureAwardsEnhancement',
  'enterprise-ui_marketClearMessaging': 'enterprise-ui_marketClearMessaging',
  'enterprise-ui_enableSeaRules': 'enterprise-ui_enableSeaRules',
  'enterprise-ui_enableSeaFindYourCustomer': 'enterprise-ui_enableSeaFindYourCustomer',
} as const;

export type Feature = keyof typeof Features;

/**
 * treatment definitions by env
 * utilized for determining how to serve treatment if split fails to load
 */
const features =
  import.meta.env.VITE_ENV === 'prod'
    ? {
        [Features['enterprise-ui_enableSetExpirationDate']]: 'off',
        [Features['enterprise-ui_enableRecurringRules']]: 'off',
        [Features['enterprise-ui_enableRecurringRulesForAccounts']]: 'off',
        [Features['enterprise-ui_fedRateBanner']]: 'off',
        [Features['enterprise-ui_enableSeaReads']]: 'on',
        [Features['enterprise-ui_enableSeaWrites']]: 'off',
        [Features['enterprise-ui_enableSeaDraftOffers']]: 'off',
        [Features['enterprise-ui_enablePendingAndFutureAwardsEnhancement']]: 'off',
        [Features['enterprise-ui_marketClearMessaging']]: 'off',
        [Features['enterprise-ui_enableSeaRules']]: 'off',
        [Features['enterprise-ui_enableSeaFindYourCustomer']]: 'off',
      }
    : {
        [Features['enterprise-ui_enableSetExpirationDate']]: 'on',
        [Features['enterprise-ui_enableRecurringRules']]: 'on',
        [Features['enterprise-ui_enableRecurringRulesForAccounts']]: 'on',
        [Features['enterprise-ui_fedRateBanner']]: 'on',
        [Features['enterprise-ui_enableSeaReads']]: 'on',
        [Features['enterprise-ui_enableSeaWrites']]: 'on',
        [Features['enterprise-ui_enableSeaDraftOffers']]: 'on',
        [Features['enterprise-ui_enablePendingAndFutureAwardsEnhancement']]: 'on',
        [Features['enterprise-ui_marketClearMessaging']]: 'on',
        [Features['enterprise-ui_enableSeaRules']]: 'on',
        [Features['enterprise-ui_enableSeaFindYourCustomer']]: 'on',
      };

function FeaturesProviderComponent({ children }: { children: ReactNode }) {
  const { data: accountId } = useUserDetails(({ me }) => me?.account?.id);
  // fallback to 'no-divisons-user' as a placeholder
  // account id to prevent split auth errors
  const key = accountId ?? 'no-divisons-user';

  const config: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: import.meta.env.VITE_SPLIT_IO_KEY ?? 'localhost',
      key,
      trafficType: 'account',
    },
    impressionListener: {
      logImpression(impressionData) {
        datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
      },
    },
    features,
    startup: {
      readyTimeout: 3,
      requestTimeoutBeforeReady: 3,
      retriesOnFailureBeforeReady: 5,
    },
  };

  return (
    <SplitFactoryProvider config={config} updateOnSdkTimedout updateOnSdkUpdate>
      {({ isReady, isTimedout }) => {
        // loading
        if (!isReady && !isTimedout) {
          return <FullPageLoader />;
        }

        // good to go
        return <div>{children}</div>;
      }}
    </SplitFactoryProvider>
  );
}

export function FeaturesProvider({ children }: { children: ReactNode }) {
  return (
    <QueryBoundaries ErrorComponent={() => <SomethingsWrong />} LoadingComponent={() => <FullPageLoader />}>
      <FeaturesProviderComponent>{children}</FeaturesProviderComponent>
    </QueryBoundaries>
  );
}

/**
 * Check if a Split treatment's value is "on".
 * @returns A tuple containing whether the treatment is on and the config object, or undefined if it exists.
 */
export default function useFeature<T = Record<string, unknown>>(featureName: Feature, attributes?: SplitIO.Attributes) {
  const { treatments } = useSplitTreatments({ names: [featureName], attributes });
  const feature = treatments[featureName];

  let treatment = feature.treatment;

  /**
   * if split fails to load and does not return a treatment, "control" will be returned
   * fallback to utilizing treatment override by env if defined
   */
  if (treatment === 'control' && features[featureName]) {
    treatment = features[featureName];
  }

  return [treatment === 'on', feature.config ? (JSON.parse(feature.config) as T) : undefined] as const;
}
