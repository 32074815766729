import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import colors from '@c2fo/liquidity/colors';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { parseDate } from './utils';

/**
 * The data required for each chart.
 */
export interface HistoryChartDatum {
  /**
   * The date of the pay period.
   */
  payDate: string;
  /**
   * The weighted average APR for the pay period.
   */
  clearedAprWeightedAvg: number;
  /**
   * The total amount of cleared invoices for the pay period.
   */
  clearedInvoiceAmount: number;
}

export interface HistoryChartProps<T extends HistoryChartDatum> {
  data?: Array<T>;
  currency?: string;
}

export default function HistoryChart<T extends HistoryChartDatum>({
  data = [],
  currency = 'USD',
}: HistoryChartProps<T>) {
  const { asCurrency, asDateString, asAbbreviatedNumber } = useLocaleFormat();
  const { t } = useTranslation();

  const combinedData = useMemo(() => {
    const clearedIncomeData = data.map((item) => ({
      x: parseDate(item.payDate).valueOf(),
      y1: item.clearedInvoiceAmount,
    }));

    return clearedIncomeData;
  }, [data]);

  return (
    <ResponsiveContainer height={300}>
      <BarChart data={combinedData}>
        <XAxis
          dataKey="x"
          tickFormatter={(value) =>
            asDateString(value, {
              day: 'numeric',
              month: 'short',
              year: undefined,
            })
          }
        />
        <YAxis tickFormatter={(value) => asAbbreviatedNumber(value as number)} />
        <Tooltip
          cursor={{ fill: colors.gray[100] }}
          formatter={(value, name) => {
            return [asCurrency(+value, currency), name];
          }}
          labelFormatter={(value) => asDateString(value)}
        />
        <Legend
          align="left"
          height={56}
          verticalAlign="top"
          wrapperStyle={{
            top: 0,
            left: 32,
          }}
        />
        <CartesianGrid stroke={colors.gray[100]} vertical={false} />
        <Bar name={t('core.awardedAR')} dataKey="y1" fill={colors.lightBlue[600]}></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
