import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  IconButton,
  useSnackbar,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import {
  ArrowLeftFromLineIcon,
  EllipsisVerticalIcon,
  PercentIcon,
  ToggleLargeOffIcon,
  ToggleLargeOnIcon,
  CalendarLinesIcon,
  PenRulerIcon,
} from '@c2fo/liquidity/icons';
import ExcludeInvoicesModal from '@/components/ExcludeInvoicesModal';
import useGlobalPriceDiscoveryOffer from '@/data/useGlobalPriceDiscoveryOffer';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import useTakerMarketsGroups, { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import NameYourRateSetGlobalOffer from '@/features/nameYourRate/SetGlobalOffer';
import NameYourRateSetGlobalParticipation from '@/features/nameYourRate/SetGlobalParticipation';
import RecurringRulesForm from '@/features/recurringRules/RecurringRulesForm';
import useEnableRecurringRules from '@/features/recurringRules/utils/useEnableRecurringRules';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import useRestrictions from '@/utils/useRestrictions';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import { SetBulkExpirationDate } from './SetBulkExpirationDate';

export const RecurringRulesMenuItem = ({ onClick, readOnly }: { onClick: () => void; readOnly: boolean }) => {
  const { t } = useTranslation();

  const menuItemContent = (
    <div className="flex w-full justify-between gap-1.5">
      <div className="inline-flex">
        <PenRulerIcon className="h-6 w-6" fill={colors.text.secondary} />
        <span className="ml-3">{t('recurringRules.createRecurringRule')}</span>
      </div>
      <Chip size="xs" type="info" variant="filled" isRound label={t('core.beta')} />
    </div>
  );

  return readOnly ? (
    <DropdownMenuItem aria-label={t('recurringRules.createRecurringRule')} disabled>
      {menuItemContent}
    </DropdownMenuItem>
  ) : (
    <DropdownMenuItem aria-label={t('recurringRules.createRecurringRule')} onClick={onClick}>
      {menuItemContent}
    </DropdownMenuItem>
  );
};

export const ExcludeInvoicesMenuItem = ({ onClick, readOnly }: { onClick: () => void; readOnly: boolean }) => {
  const { t } = useTranslation();

  const menuItemContent = (
    <>
      <ArrowLeftFromLineIcon className="h-6 w-6" fill={colors.text.secondary} />
      <span className="ml-3">{t('core.exclude')}</span>
    </>
  );

  return readOnly ? (
    <DropdownMenuItem disabled>{menuItemContent}</DropdownMenuItem>
  ) : (
    <DropdownMenuItem onClick={onClick}>{menuItemContent}</DropdownMenuItem>
  );
};

const GlobalOfferMenuItem = ({ onClick, readOnly }: { onClick: () => void; readOnly: boolean }) => {
  const { t } = useTranslation();

  const menuItemContent = (
    <>
      <PercentIcon className="h-6 w-6" fill={colors.text.secondary} />
      <span className="ml-3">{t('takerMarketTabs.setOneOfferForAllDivisions')}</span>
    </>
  );

  return readOnly ? (
    <DropdownMenuItem disabled>{menuItemContent}</DropdownMenuItem>
  ) : (
    <DropdownMenuItem onClick={onClick}>{menuItemContent}</DropdownMenuItem>
  );
};

const GlobalParticipationMenuItems = ({
  onParticipationOffClick,
  onParticipationOnClick,
  readOnly,
}: {
  onParticipationOffClick: () => void;
  onParticipationOnClick: () => void;
  readOnly: boolean;
}) => {
  const { t } = useTranslation();

  const participationOnMenuItemContent = (
    <>
      <ToggleLargeOnIcon className="h-6 w-6" fill={colors.text.secondary} />
      <span className="ml-3">{t('takerMarketTabs.allParticipationOn')}</span>
    </>
  );

  const participationOffMenuItemContent = (
    <>
      <ToggleLargeOffIcon className="h-6 w-6" fill={colors.text.secondary} />
      <span className="ml-3">{t('takerMarketTabs.allParticipationOff')}</span>
    </>
  );

  return readOnly ? (
    <>
      <DropdownMenuItem disabled>{participationOnMenuItemContent}</DropdownMenuItem>
      <DropdownMenuItem disabled>{participationOffMenuItemContent}</DropdownMenuItem>
    </>
  ) : (
    <>
      <DropdownMenuItem onClick={onParticipationOnClick}>{participationOnMenuItemContent}</DropdownMenuItem>
      <DropdownMenuItem onClick={onParticipationOffClick}>{participationOffMenuItemContent}</DropdownMenuItem>
    </>
  );
};

const GlobalExpirationDateMenuItem = ({ onClick, readOnly }: { onClick: () => void; readOnly: boolean }) => {
  const { t } = useTranslation();

  const menuItemContent = (
    <>
      <CalendarLinesIcon className="h-6 w-6" fill={colors.text.secondary} />
      <span className="ml-3">{t('expirationDate.bulkUpdate.title')}</span>
    </>
  );

  return readOnly ? (
    <DropdownMenuItem disabled>{menuItemContent}</DropdownMenuItem>
  ) : (
    <DropdownMenuItem onClick={onClick}>{menuItemContent}</DropdownMenuItem>
  );
};

const TabActionsDropdown = ({ tab }: { tab: TakerMarketGroupType }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const selectedCurrency = useSelectedCurrency();
  const takerMarketsGroups = useTakerMarketsGroups();
  const { getRestrictions } = useRestrictions();
  const { canEditInvoices, canEditOffers, canEditRecurringRules } = getRestrictions(
    takerMarketsGroups[tab].takerMarkets
  );
  const [showSetGlobalOfferModal, setShowSetGlobalOfferModal] = useState<boolean>(false);
  const [showExcludeInvoicesModal, setShowExcludeInvoicesModal] = useState<boolean>(false);
  const [showSetGlobalParticipationModal, setShowSetGlobalParticipationModal] = useState<boolean>(false);
  const [showBulkExpirationDateModal, setShowBulkExpirationDateModal] = useState<boolean>(false);
  const [showRecurringRulesModal, setShowRecurringRulesModal] = useState<boolean>(false);
  const enableRecurringRules = useEnableRecurringRules();
  const [isSetExpirationDateEnabled] = useFeature('enterprise-ui_enableSetExpirationDate');
  const { data: disableApr = false } = useTakerMarkets(
    useCallback((takerMarkets: TakerMarket[]) => {
      const disableApr = takerMarkets.some((takerMarket) => takerMarket.isDiscountMarket);
      return disableApr;
    }, [])
  );
  const { mutateAsync: toggleGlobalPriceDiscoveryParticipationOff } = useGlobalPriceDiscoveryOffer();

  const turnGlobalParticipationOff = async () => {
    if (!canEditOffers) {
      return;
    }

    const submitValues = { isEnabled: false };

    await toggleGlobalPriceDiscoveryParticipationOff(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdate') });
        track('global-participation::submitted', submitValues);
      },
      onError: () => showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdateError') }),
    });
  };

  const openSetGlobalOfferModal = () => {
    setShowSetGlobalOfferModal(true);
    track('global-offer::clicked');
  };

  const openSetGlobalParticipationModal = () => {
    setShowSetGlobalParticipationModal(true);
    track('global-participation::clicked');
  };

  const openExcludeInvoicesModal = () => {
    setShowExcludeInvoicesModal(true);
    track('exclude-invoices::clicked', { type: tab });
  };

  const openRecurringRulesModal = () => {
    setShowRecurringRulesModal(true);
    track('recurring-rules::opened', {
      referrer: 'tab-actions-dropdown',
      source: tab,
    });
  };

  const closeRecurringRulesModal = () => {
    setShowRecurringRulesModal(false);
    track('recurring-rules::closed');
  };

  const openTabActionsDropdown = () => {
    track('tab-actions::clicked', { type: tab });
  };

  const isBulkExpirationDisabled = useMemo(() => {
    // Ensure feature flag is enabled
    if (!isSetExpirationDateEnabled) {
      return true;
    }

    // Only allow supported offer types
    if (!['NAME_YOUR_RATE', 'BENCHMARK'].includes(tab)) {
      return true;
    }

    // At least one offer within the selected tab must be enabled
    const isSomeTabTypeOffersEnabled = takerMarketsGroups[tab].takerMarkets.some(
      (takerMarket) => takerMarket.offerConfig?.isEnabled
    );

    if (!isSomeTabTypeOffersEnabled) {
      return true;
    }

    return false;
  }, [isSetExpirationDateEnabled, tab, takerMarketsGroups]);

  const openExpirationDate = () => {
    // Failsafe for not allowing expiration date update if bulk expiration date is disabled
    if (isBulkExpirationDisabled) {
      return;
    }

    setShowBulkExpirationDateModal(true);
    track('bulk-expiration-date-update::opened');
  };

  return (
    <IntercomDataTarget target="tabActionsDropdown">
      <RecurringRulesForm
        onClose={closeRecurringRulesModal}
        open={showRecurringRulesModal}
        referrer="homepage"
        takerMarkets={takerMarketsGroups[tab].takerMarkets}
        type={tab}
      />
      <NameYourRateSetGlobalOffer
        disableApr={disableApr}
        numMarkets={takerMarketsGroups[tab].rollup.count}
        onClose={() => setShowSetGlobalOfferModal(false)}
        open={showSetGlobalOfferModal}
      />
      <NameYourRateSetGlobalParticipation
        onClose={() => setShowSetGlobalParticipationModal(false)}
        open={showSetGlobalParticipationModal}
      />
      <ExcludeInvoicesModal
        open={showExcludeInvoicesModal}
        onClose={() => setShowExcludeInvoicesModal(false)}
        takerMarkets={takerMarketsGroups[tab].takerMarkets}
        toCurrency={selectedCurrency}
        type={tab}
      />
      <SetBulkExpirationDate
        open={showBulkExpirationDateModal}
        onClose={() => setShowBulkExpirationDateModal(false)}
        takerMarkets={takerMarketsGroups[tab].takerMarkets}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild onClick={openTabActionsDropdown}>
          <IconButton icon={EllipsisVerticalIcon} name={t('takerMarketTabs.tabActions')} variant="cta" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" collisionPadding={24} avoidCollisions={false} className="min-w-64">
          <DropdownMenuLabel>{t('core.invoiceActions')}</DropdownMenuLabel>
          {enableRecurringRules && (
            <RecurringRulesMenuItem onClick={openRecurringRulesModal} readOnly={!canEditRecurringRules} />
          )}
          <ExcludeInvoicesMenuItem onClick={openExcludeInvoicesModal} readOnly={!canEditInvoices} />
          <DropdownMenuSeparator />
          <DropdownMenuLabel>{t('core.globalActions')}</DropdownMenuLabel>
          {tab === 'NAME_YOUR_RATE' && (
            <GlobalOfferMenuItem onClick={openSetGlobalOfferModal} readOnly={!canEditOffers} />
          )}
          <GlobalParticipationMenuItems
            onParticipationOffClick={turnGlobalParticipationOff}
            onParticipationOnClick={openSetGlobalParticipationModal}
            readOnly={!canEditOffers || tab !== 'NAME_YOUR_RATE'}
          />
          {isSetExpirationDateEnabled && (
            <GlobalExpirationDateMenuItem
              onClick={openExpirationDate}
              readOnly={!canEditOffers || isBulkExpirationDisabled}
            />
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </IntercomDataTarget>
  );
};

export default TabActionsDropdown;
