import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@c2fo/liquidity/icons';
import { useOfferDiscountAPRDisplay } from '../utils/useOfferDiscountAPRDisplay';

const OfferDiscountAPRDisplay = ({
  isDiscountBidding,
  maxDiscount,
  maxApr,
  onClick,
  readOnly = false,
  disabled = false,
}: {
  isDiscountBidding?: boolean | null;
  maxDiscount?: number | null;
  maxApr?: number | null;
  onClick?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { offerDiscountAPRDisplay } = useOfferDiscountAPRDisplay();

  const rateDisplay = isDiscountBidding
    ? offerDiscountAPRDisplay(maxDiscount ?? 0, t('core.discountAbbreviation'))
    : offerDiscountAPRDisplay(maxApr ?? 0, t('core.aprAbbreviation'));

  if (readOnly) {
    return <div className="flex items-center justify-end p-2 uppercase">{rateDisplay}</div>;
  }

  return (
    <div className="flex items-center justify-end">
      <button
        className="-mr-2 flex items-center gap-2 rounded p-2 uppercase hover:bg-gray-100"
        onClick={onClick}
        type="button"
        disabled={disabled}
      >
        {rateDisplay}
        <PencilIcon />
      </button>
    </div>
  );
};

export default OfferDiscountAPRDisplay;
