import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import useFeature, { Features } from '../lib/features';
import { UpdateRecurringRuleArgument } from './useUpdateRecurringRule';

type SEAInvoiceRulesDeleteRequestBody = paths['/invoice-rules']['delete']['requestBody']['content']['application/json'];

/**
 * hook for deleting a recurring rule and all its criteria
 */
export interface DeleteRecurringRuleArgument {
  id: UpdateRecurringRuleArgument['id'];
  makerOrganizationUuid: UpdateRecurringRuleArgument['makerOrganizationUuid'];
  marketId: UpdateRecurringRuleArgument['marketId'];
  takerId: UpdateRecurringRuleArgument['takerId'];
  takerUuid: UpdateRecurringRuleArgument['takerUuid'];
  marketUuid: UpdateRecurringRuleArgument['marketUuid'];
}

const useDeleteRecurringRule = () => {
  const [enableSEARulesApi] = useFeature(Features['enterprise-ui_enableSeaRules']);
  const { track } = useReporting();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteRecurringRule'],
    mutationFn: (argument: DeleteRecurringRuleArgument | DeleteRecurringRuleArgument[]) => {
      const argumentsArray = Array.isArray(argument) ? argument : [argument];
      const { id, makerOrganizationUuid } = argumentsArray[0];

      if (enableSEARulesApi) {
        return apiClient.delete(`api/supplier-experience/invoice-rules`, {
          json: {
            groupingKey: 'SUPPLIER_MARKET',
            rules: argumentsArray.map((argument) => ({
              uuid: argument.id,
              makerOrganizationUuid: argument.makerOrganizationUuid,
            })),
            supplierMarkets: argumentsArray.map((argument) => ({
              marketUuid: argument.marketUuid,
              supplierDivisionUuid: argument.takerUuid,
            })),
          } satisfies SEAInvoiceRulesDeleteRequestBody,
        });
      }

      return apiClient.delete(
        `api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules/${id}`
      );
    },
    onSuccess: (_, variables) => {
      const variablesArray = Array.isArray(variables) ? variables : [variables];
      const { makerOrganizationUuid, marketId, takerId, takerUuid, marketUuid } = variablesArray[0];

      if (enableSEARulesApi) {
        // Send trackEvent for each rule argument that came in the delete request
        variablesArray.forEach((argument) => {
          track('recurring-rules::deleted', {
            makerOrganizationUuid: argument.makerOrganizationUuid,
            takerMarkets: [
              {
                marketId: argument.marketId,
                takerId: argument.takerUuid,
                takerUuid: argument.takerId,
                marketUuid: argument.marketUuid,
              },
            ],
          });
        });

        queryClient.refetchQueries(['recurring-rules']);
      } else {
        track('recurring-rules::deleted', {
          makerOrganizationUuid,
          takerMarkets: [{ marketId, takerId, takerUuid, marketUuid }],
        });
        queryClient.refetchQueries(['recurring-rules', makerOrganizationUuid]);
      }
    },
  });
};

export default useDeleteRecurringRule;
