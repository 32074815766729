import { useTranslation } from 'react-i18next';

const FindYourCustomer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-8">
        <h1 className="m-0 text-4xl">{t('registration.anonymousCallToAction')}</h1>
        <div className="pt-2">{t('taker.marketSearch.title')}</div>
      </div>
    </div>
  );
};

export default FindYourCustomer;
