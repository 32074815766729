import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInverse, useSnackbar } from '@c2fo/liquidity';
import useDeleteRecurringRule from '@/data/useDeleteRecurringRule';
import useEditInvoices from '@/data/useEditInvoices';
import { TakerMarketWithRecurringRule } from '@/data/useRecurringRules';
import { useReporting } from '@/reporting';
import useLocaleFormat from '@/utils/useLocaleFormat';
import InvoiceReinclusion, { InvoiceReinclusionProps } from './InvoiceReinclusion';

interface DeleteRuleModalProps {
  onClose: () => void;
  open: boolean;
  takerMarketWithRule: TakerMarketWithRecurringRule;
}

const DeleteRuleModal = ({ open, onClose, takerMarketWithRule }: DeleteRuleModalProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const [showError, setShowError] = useState(false);
  const [contactSRM, setContactSRM] = useState(false);
  const [handleInvoices, setHandleInvoices] = useState<'include' | 'exclude'>('include');
  const { mutateAsync: deleteRecurringRule, isLoading } = useDeleteRecurringRule();
  const { asCurrency } = useLocaleFormat();
  const { includeInvoices } = useEditInvoices();

  if (!takerMarketWithRule) {
    return null;
  }

  const { rule, takerMarket } = takerMarketWithRule;
  const excludedInvoicesCount = takerMarket.takerExcludedInvoiceCount;
  const excludedInvoicesAmount = asCurrency(takerMarket.takerExcludedInvoiceAmount, takerMarket.currency);

  const handleClose = () => {
    setContactSRM(false);
    setHandleInvoices('include');
    onClose();
  };

  const handleInvoicesChange = (value: 'include' | 'exclude') => {
    setHandleInvoices(value);
  };

  const deleteRule = async () => {
    track('recurring-rules::delete::invoice-reinclusion', { type: handleInvoices, ruleId: rule.id });

    await deleteRecurringRule(
      {
        id: rule.id,
        makerOrganizationUuid: rule.makerOrganizationUuid,
        marketId: rule.marketId,
        takerId: rule.takerId,
        takerUuid: rule.takerUuid,
        marketUuid: rule.marketUuid,
      },
      {
        onSuccess: () => {
          handleClose();
          showSnackbar({ message: t('recurringRules.deleteAllRulesSuccess') });
        },
        onError: () => {
          setShowError(true);
        },
      }
    );

    if (handleInvoices === 'include' && excludedInvoicesCount > 0) {
      await includeInvoices({ filters: { all: true }, takerMarkets: [takerMarket] });
    }
  };

  const invoiceReinclusionProps = {
    excludedInvoicesCount,
    excludedInvoicesAmount,
    handleInvoices,
    handleInvoicesChange,
  } satisfies InvoiceReinclusionProps;

  return (
    <Modal disableOutsideClickClose={isLoading} onClose={handleClose} open={open} size="sm">
      <ModalTitleInverse>{t('recurringRules.deleteRules')}</ModalTitleInverse>
      <ModalContent className="space-y-4">
        {showError && (
          <>
            {contactSRM ? (
              <Alert
                description={t('core.contactSrm.description')}
                full
                title={t('core.contactSrm.title')}
                type="info"
              />
            ) : (
              <Alert
                action={{
                  onClick: () => {
                    // fire track event for slack channel
                    track('recurring-rules::error', { type: 'delete', ruleId: rule.id });
                    setContactSRM(true);
                  },
                  text: t('core.contactSrm'),
                }}
                description={t('core.reachOutForAssistance')}
                full
                title={t('recurringRules.error.deleteRules')}
                type="error"
              />
            )}
          </>
        )}
        <div>{t('recurringRules.deleteAllRulesWarning')}</div>
        <ul className="ml-4 list-disc space-y-1">
          <li>{t('recurringRules.deleteParticipationDescription')}</li>
          <li>{t('recurringRules.deleteFutureInvoicesDescription')}</li>
        </ul>
        <InvoiceReinclusion {...invoiceReinclusionProps} />
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={isLoading} onClick={deleteRule} variant="destructive">
          {t('recurringRules.deleteRules')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteRuleModal;
