import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Paper,
  Popover,
  Select,
  SelectContent,
  SelectFilterTrigger,
  SelectItem,
  SelectValue,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  FilterIcon,
  InfoCircleIcon,
  MoneyBillIcon,
  ShapesIcon,
  TimesIcon,
  TrashCanIcon,
} from '@c2fo/liquidity/icons';
import emptystatements from '@/assets/emptystatementsgrey.svg?url';
import invoices from '@/assets/invoices.svg?url';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import NoDataState from '@/components/NoDataState';
import {
  Table,
  TableBody,
  TableCell,
  TableDisplay,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import useRecurringRules, {
  RecurringRule,
  RecurringRuleCategory,
  TakerMarketWithRecurringRule,
} from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import DeleteAllRulesModal from './components/DeleteAllRulesModal';
import DeleteRuleCriteriaModal from './components/DeleteRuleCriteriaModal';
import DeleteRuleModal from './components/DeleteRuleModal';
import Pagination, { getStartEnd } from './components/Pagination';
import RecurringRulesForm, { RecurringRulesFormProps } from './RecurringRulesForm';
import SetRuleCriteria from './SetRuleCriteria';
import getIsBeta from './utils/getIsBeta';
import getRecurringRuleForCategory from './utils/getRecurringRuleForCategory';
import sortRulesTable, { Sort, SortKey } from './utils/sortRulesTable';

interface RulesTableFilters {
  currency?: string;
  ruleCategory?: RecurringRuleCategory;
}

const ruleCategoryFilters: { key: RecurringRuleCategory; translationKey: string }[] = [
  {
    key: 'dpe',
    translationKey: 'core.daysPaidEarly',
  },
  {
    key: 'dueDate',
    translationKey: 'recurringRules.dueDate.category.label',
  },
  {
    key: 'amount',
    translationKey: 'core.invoiceAmount',
  },
  {
    key: 'invoiceId',
    translationKey: 'core.invoiceId',
  },
];

const ruleCategories = ruleCategoryFilters.map((category) => category.key);

/**
 * sort and filter rules based on search, sort, and filters
 */
const sortAndFilterRules = ({
  filters,
  recurringRules,
  search,
  sort,
}: {
  recurringRules: TakerMarketWithRecurringRule[];
  search: string;
  sort: Sort;
  filters?: RulesTableFilters;
}) => {
  return sortRulesTable(recurringRules, sort).filter(({ takerMarket, rule }) => {
    if (
      !getTakerMarketName(takerMarket).toLowerCase().includes(search.toLowerCase()) &&
      !getTakerMarketDivisionTitle(takerMarket)?.title.toLowerCase().includes(search.toLowerCase())
    ) {
      return false;
    }

    if (
      filters?.ruleCategory &&
      !getRecurringRuleForCategory(rule, filters.ruleCategory)?.[
        filters.ruleCategory === 'invoiceId' ? 'excludedVoucherIds' : filters.ruleCategory
      ]
    ) {
      return false;
    }

    if (filters?.currency && takerMarket.currency !== filters.currency) {
      return false;
    }

    return true;
  });
};

const DivisionTitle = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const takerMarketName = getTakerMarketName(takerMarket);
  const buyerDivisionName = getTakerMarketDivisionTitle(takerMarket)?.content;
  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

  return (
    <div className="flex flex-col">
      <EllipsisTooltip
        content={takerMarketName}
        trigger={
          <Link
            className="font-bold"
            to={`/supplier/markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
          >
            {takerMarketName}
          </Link>
        }
      />
      <div className="text-sm text-text-primary">{takerMarket.currency}</div>
      <EllipsisTooltip content={buyerDivisionName} trigger={<span className="text-sm">{buyerDivisionName}</span>} />
      {isPreferred && (
        <div className="flex gap-1">
          <Popover>
            <Popover.Trigger>
              <InfoCircleIcon className="h-4 w-4" fill={colors.gray[600]} />
            </Popover.Trigger>
            <Popover.Content arrow side="bottom">
              {t('recurringRules.preferredOfferLocked')}
            </Popover.Content>
          </Popover>
          <span className="text-wrap text-sm italic text-text-secondary">{t('core.preferredOffer')}</span>
        </div>
      )}
    </div>
  );
};

const RulesTable = ({
  showInformationRequest,
  loadingInformationRequest,
  disableInformationRequest,
}: {
  showInformationRequest: () => void;
  loadingInformationRequest: boolean;
  disableInformationRequest: boolean;
}) => {
  const isBeta = getIsBeta();
  const { t } = useTranslation();
  const { track } = useReporting();
  const recurringRules = useRecurringRules();
  const { asCurrency, asNumber } = useLocaleFormat();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState<Sort>({ key: 'totalAr', direction: 'desc' });
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<RulesTableFilters>({
    currency: undefined,
    ruleCategory: undefined,
  });
  const filteredRules = sortAndFilterRules({ filters, recurringRules, search, sort });
  const filteredCount = filteredRules.length;
  const totalCount = recurringRules.length;
  const { start, end } = getStartEnd({ limit, page, totalCount });
  const { start: filteredStart, end: filteredEnd } = getStartEnd({ limit, page, totalCount: filteredCount });
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);
  // create/edit rule
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [ruleModalProps, setRuleModalProps] = useState<
    Pick<RecurringRulesFormProps, 'defaultValues' | 'mode' | 'takerMarkets' | 'type'>
  >({
    takerMarkets: [],
  });
  // delete all rules
  const [openDeleteAllRulesModal, setOpenDeleteAllRulesModal] = useState(false);
  // delete rule
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState<TakerMarketWithRecurringRule | null>(null);
  // delete rule criteria
  const [openDeleteRuleCriteriaModal, setOpenDeleteRuleCriteriaModal] = useState(false);
  const [ruleCriteriaToDelete, setRuleCriteriaToDelete] = useState<{
    rule: RecurringRule;
    category: RecurringRuleCategory;
  } | null>(null);
  const [expandedRule, setExpandedRule] = useState<RecurringRule | null>(null);
  const hasFilterOrSearch = !!filters.currency || !!filters.ruleCategory || search !== '';

  const selectedRulesText =
    filteredCount === 1 ? (
      <div className="text-sm font-medium">
        {search !== '' || !Object.values(filters).every((filter) => filter === undefined)
          ? t('divisions.filterMatchingCount', { filteredCount, start: filteredStart, end: filteredEnd })
          : t('divisions.filterCount', { filteredCount, start: filteredStart, end: filteredEnd })}
      </div>
    ) : (
      <div className="text-sm font-medium">
        {search !== '' || !Object.values(filters).every((filter) => filter === undefined)
          ? t('divisions.filterMatchingCountPlural', { filteredCount, start: filteredStart, end: filteredEnd })
          : t('divisions.filterCountPlural', { filteredCount, start: filteredStart, end: filteredEnd })}
      </div>
    );

  const onPageSizeChange = (limit: number) => {
    setLimit(limit);
    setPage(1);
  };

  const onSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'takerMarketName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const onSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const tableHeadColumns: { accessorKey?: SortKey; translation: string; className: string }[] = [
    {
      accessorKey: 'takerMarketName',
      translation: t('core.division'),
      className: 'w-72',
    },
    {
      accessorKey: 'totalAr',
      translation: t('core.totalAR'),
      className: 'w-36',
    },
    {
      accessorKey: 'ruleCount',
      translation: t('core.rules'),
      className: 'w-24',
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('core.amountIncluded'),
      className: 'w-40',
    },
    {
      accessorKey: 'eligibleInvoiceCount',
      translation: t('draftOffer.labels.invoicesIncluded'),
      className: 'w-40',
    },
    {
      accessorKey: 'takerExcludedInvoiceAmount',
      translation: t('core.amountExcluded'),
      className: 'w-40',
    },
    {
      accessorKey: 'takerExcludedInvoiceCount',
      translation: t('core.invoicesExcluded'),
      className: 'w-40',
    },
    {
      translation: '',
      className: 'w-36',
    },
  ];

  const handleOpenRuleModal = ({
    defaultValues,
    takerMarkets,
    type,
  }: Pick<RecurringRulesFormProps, 'defaultValues' | 'takerMarkets' | 'type'>) => {
    setRuleModalProps({
      defaultValues,
      takerMarkets,
      type,
      ...((defaultValues?.criteria?.length ?? 0) > 0 && { mode: 'edit' }),
    });
    setOpenRuleModal(true);
    track('recurring-rules::opened', {
      referrer: 'rules-manager',
      source: 'table',
    });
  };

  const handleOpenDeleteRuleModal = (rule: RecurringRule, category: RecurringRuleCategory) => {
    setOpenDeleteRuleCriteriaModal(true);
    setRuleCriteriaToDelete({ rule, category });
  };

  const handleCloseRuleModal = () => {
    setOpenRuleModal(false);
    track('recurring-rules::closed');
  };

  const handleDeleteAllRulesModalOpen = (rule: TakerMarketWithRecurringRule) => {
    setOpenDeleteRuleModal(true);
    setRuleToDelete(rule);
  };

  const handleDeleteAllRulesModalClose = () => {
    setOpenDeleteAllRulesModal(false);
  };

  const handleDeleteAllRulesModalSuccess = async () => {
    setFilters({
      currency: undefined,
      ruleCategory: undefined,
    });
    setSearch('');
    track('recurring-rules::bulk-delete::all', {
      referrer: 'rules-manager',
    });
  };

  const handleRuleExpand = (rule: RecurringRule) => {
    if (expandedRule?.id === rule.id) {
      setExpandedRule(null);
    } else {
      setExpandedRule(rule);
    }
  };

  return (
    <div>
      <RecurringRulesForm
        isForSingleDivision
        onClose={handleCloseRuleModal}
        open={openRuleModal}
        referrer="rulesManager"
        {...ruleModalProps}
      />
      <DeleteAllRulesModal
        hasFilterOrSearch={hasFilterOrSearch}
        onClose={handleDeleteAllRulesModalClose}
        onSuccess={handleDeleteAllRulesModalSuccess}
        open={openDeleteAllRulesModal}
        recurringRules={filteredRules}
      />
      <DeleteRuleCriteriaModal
        onClose={() => {
          setOpenDeleteRuleCriteriaModal(false);
          setRuleCriteriaToDelete(null);
        }}
        open={openDeleteRuleCriteriaModal}
        ruleMeta={ruleCriteriaToDelete!}
      />
      <DeleteRuleModal
        onClose={() => {
          setOpenDeleteRuleModal(false);
          setRuleToDelete(null);
          track('recurring-rules::bulk-delete::division', {
            makerOrganizationUuid: ruleToDelete!.rule.makerOrganizationUuid,
            referrer: 'rules-manager',
            takerMarkets: [{ marketId: ruleToDelete!.rule.marketId, takerId: ruleToDelete!.rule.takerId }],
          });
        }}
        open={openDeleteRuleModal}
        takerMarketWithRule={ruleToDelete!}
      />
      {isBeta && (
        <Paper className="mb-11 px-8 py-6" elevation={2}>
          <div className="flex flex-col items-center gap-7 md:flex-row">
            <div className="shrink-0">
              <img src={invoices} alt={t('recurringRules.createRecurringRule')} className="mx-auto h-20 w-20" />
            </div>
            <div>
              <div className="mb-2 text-xl font-medium">{t('recurringRules.noActiveRulesTitleBeta')}</div>
              <div>{t('recurringRules.noActiveRulesMessageBeta')}</div>
            </div>
            <div className="w-full shrink-0 md:w-fit">
              <Button
                onClick={showInformationRequest}
                variant="secondary"
                loading={loadingInformationRequest}
                disabled={disableInformationRequest}
              >
                {t('recurringRules.noActiveRulesCtaBeta')}
              </Button>
            </div>
          </div>
        </Paper>
      )}
      <h2 className="m-0 pb-8 text-2xl">{t('recurringRules.activeRules')}</h2>
      <Paper className="px-8 pt-8" elevation={2}>
        <div className="rounded-t border border-stroke bg-canvas px-6 py-4">
          <div className="flex flex-col justify-between gap-4 lg:flex-row">
            {/* Filters */}
            <div className="flex flex-col items-center justify-between gap-4 lg:flex-row">
              <div className="flex w-full items-center gap-4">
                <FilterIcon className="h-5 w-5 shrink-0" fill={colors.text.secondary} />
                {/* Rule Category Filter */}
                <Select
                  onValueChange={(value) => {
                    setFilters({
                      ...filters,
                      ruleCategory: value === 'all' ? undefined : (value as RecurringRuleCategory),
                    });
                    setPage(1);
                  }}
                  value={filters.ruleCategory ?? 'all'}
                >
                  <SelectFilterTrigger
                    icon={ShapesIcon}
                    iconFill="fill-primary-500"
                    label={t('recurringRules.ruleCategory')}
                    size="sm"
                  >
                    <SelectValue />
                  </SelectFilterTrigger>
                  <SelectContent>
                    {[{ key: 'all', label: t('maker.all'), value: 'all' }]
                      .concat(
                        ruleCategoryFilters.map((categories) => ({
                          key: categories.key,
                          value: categories.key,
                          label: t(categories.translationKey),
                        }))
                      )
                      .map((item) => (
                        <SelectItem value={item.value} key={item.key}>
                          {item.label}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
              {/* Currency Filter */}
              <Select
                onValueChange={(value) => {
                  setFilters({ ...filters, currency: value === 'all' ? undefined : value });
                  setPage(1);
                }}
                value={filters.currency ?? 'all'}
              >
                <SelectFilterTrigger
                  icon={MoneyBillIcon}
                  iconFill="fill-primary-500"
                  label={t('core.currency')}
                  size="sm"
                >
                  <SelectValue />
                </SelectFilterTrigger>
                <SelectContent>
                  {[{ key: 'all', label: t('maker.all'), value: 'all' }]
                    .concat(
                      recurringRules
                        .map(({ takerMarket }) => takerMarket.currency)
                        .filter((val, index, curVal) => curVal.indexOf(val) === index)
                        .map((curr) => ({
                          key: curr,
                          label: curr,
                          value: curr,
                        }))
                    )
                    .map((item) => (
                      <SelectItem value={item.value} key={item.key}>
                        {item.label}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            {/* Search and Menu */}
            <div className="inline-flex items-center gap-2">
              <div className="relative grow">
                <input
                  aria-label={t('core.search')}
                  className="w-full rounded border border-stroke py-1.5 pl-3 pr-10 placeholder:text-text-secondary focus:border-lightBlue-500 focus:outline-none focus:ring-2 focus:ring-lightBlue-500"
                  name="Search"
                  onChange={(e) => onSearch(e.target.value)}
                  placeholder={t('core.search')}
                  type="text"
                  value={search}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  {search !== '' && (
                    <button
                      className="rounded-full p-1 hover:bg-secondary-100"
                      onClick={() => onSearch('')}
                      type="button"
                    >
                      <TimesIcon className="h-5 w-5" fill={colors.text.secondary} />
                    </button>
                  )}
                </div>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <IconButton
                    icon={EllipsisVerticalIcon}
                    name={t('recurringRules.menu')}
                    size="sm"
                    variant="ancillary"
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" avoidCollisions={false} collisionPadding={24}>
                  <DropdownMenuItem
                    disabled={!canEditRecurringRules || filteredCount === 0}
                    {...(canEditRecurringRules && { onClick: () => setOpenDeleteAllRulesModal(true) })}
                  >
                    {hasFilterOrSearch
                      ? t('recurringRules.deleteAllFilteredRules')
                      : t('recurringRules.deleteAllRules')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="pt-4">{selectedRulesText}</div>
        </div>
        <div className="w-full overflow-auto">
          <IntercomDataTarget target="rulesTable" className="overflow-auto rounded-b border border-t-0 border-stroke">
            <Table>
              <TableHeader className="border-b-0 bg-canvas">
                <TableRow className="border-stroke">
                  {tableHeadColumns.map(({ accessorKey, translation, className }) => (
                    <TableHead
                      key={translation}
                      className={cn('text-right', className)}
                      {...(!!accessorKey && { onClick: () => onSort(accessorKey) })}
                    >
                      {!!accessorKey ? (
                        <TableSortArrow
                          accessorKey={accessorKey}
                          sort={sort}
                          {...(!['takerMarketName'].includes(accessorKey) && { textRight: true })}
                        >
                          {translation}
                        </TableSortArrow>
                      ) : (
                        translation
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredRules.length === 0 ? (
                  <TableDisplay colSpan={8}>
                    <NoDataState
                      className="py-8"
                      icon={<img src={emptystatements} alt="emptystatements" className="h-28 w-28" />}
                      title={t('taker.noMatchingDivisions')}
                      message={t('taker.noMatchingDivisionsDescription')}
                    />
                  </TableDisplay>
                ) : (
                  filteredRules.slice(start - 1, end).map((takerMarketWithRule) => {
                    const { rule, takerMarket } = takerMarketWithRule;
                    const { canEditRecurringRules } = getRestrictions([takerMarket]);
                    const ruleCategoryMap = ruleCategories.filter((category) =>
                      Object.keys(rule).includes(category === 'invoiceId' ? 'excludedVoucherIds' : category)
                    );

                    return (
                      <Fragment key={rule.id}>
                        <TableRow className={cn('border-stroke', { 'border-b-0': expandedRule?.id === rule.id })}>
                          <TableCell>
                            <DivisionTitle takerMarket={takerMarket} />
                          </TableCell>
                          <TableCell className="text-right">
                            {asCurrency(
                              takerMarket.eligibleInvoiceAmount + takerMarket.takerExcludedInvoiceAmount,
                              takerMarket.currency
                            )}
                          </TableCell>
                          <TableCell className="text-right">{rule.count}</TableCell>
                          <TableCell className="text-right">
                            {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                          </TableCell>
                          <TableCell className="text-right">
                            {asNumber({ value: takerMarket.eligibleInvoiceCount })}
                          </TableCell>
                          <TableCell className="text-right">
                            {asCurrency(takerMarket.takerExcludedInvoiceAmount, takerMarket.currency)}
                          </TableCell>
                          <TableCell className="text-right">
                            {asNumber({ value: takerMarket.takerExcludedInvoiceCount })}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center justify-end">
                              <IconButton
                                className={cn({ 'rotate-180': expandedRule?.id === rule.id })}
                                icon={ChevronDownIcon}
                                name={t('recurringRules.expandRules')}
                                onClick={() => handleRuleExpand(rule)}
                                size="md"
                                variant="ancillary"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {expandedRule?.id === rule.id && (
                          <TableRow className="border-b border-stroke">
                            <TableCell className="pt-0" colSpan={8}>
                              <div className="flex items-center justify-between gap-10 bg-canvas p-4">
                                <div className="text-left">
                                  <div className="mb-3 text-sm font-medium text-text-secondary">
                                    {t('recurringRules.excludingInvoicesWhichWhichMeetAllCriteria')}:
                                  </div>
                                  <div className="flex flex-wrap items-center gap-3">
                                    {ruleCategoryMap.map((ruleCategory, i) => {
                                      return (
                                        <Fragment key={ruleCategory}>
                                          <SetRuleCriteria
                                            ruleCategory={ruleCategory}
                                            takerMarketWithRule={takerMarketWithRule}
                                            setModalProps={handleOpenRuleModal}
                                            onDelete={() => handleOpenDeleteRuleModal(rule, ruleCategory)}
                                          />
                                          {i < ruleCategoryMap.length - 1 && (
                                            <span className="text-sm text-text-secondary">
                                              {t('invoiceSettings.modal.ruleConjunction')}
                                            </span>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="flex items-center gap-3">
                                  {/* Add Rule button disabled if cannot edit rules or rule has 1 of each 4 criteria */}
                                  <Button
                                    disabled={!canEditRecurringRules || expandedRule.count === 4}
                                    onClick={() => handleOpenRuleModal({ takerMarkets: [takerMarket] })}
                                    size="sm"
                                    variant="secondary"
                                  >
                                    {t('recurringRules.addRule')}
                                  </Button>
                                  <IconButton
                                    className="bg-transparent"
                                    disabled={!canEditRecurringRules}
                                    icon={TrashCanIcon}
                                    inverse
                                    name={t('recurringRules.removeRule')}
                                    onClick={() => handleDeleteAllRulesModalOpen(takerMarketWithRule)}
                                    size="sm"
                                    variant="destructive"
                                  />
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </IntercomDataTarget>
        </div>
        <div className="flex justify-center p-4">
          <Pagination
            limit={limit}
            onPageChange={setPage}
            onPageSizeChange={onPageSizeChange}
            page={page}
            totalCount={filteredCount}
          />
        </div>
      </Paper>
    </div>
  );
};

export default RulesTable;
