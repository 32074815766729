import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Popover,
} from '@c2fo/liquidity';
import { EllipsisVerticalIcon } from '@c2fo/liquidity/icons';
import { RecurringRule, RecurringRuleCategory, TakerMarketWithRecurringRule } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import getMarketType from '@/utils/getMarketType';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import { RecurringRuleFormValues } from './RecurringRulesForm';
import useRuleTitles from './utils/getRulesTitles';
import { mapRecurringRuleToFormCriteria } from './utils/utils';

interface SetRuleCriteriaProps {
  ruleCategory: RecurringRuleCategory;
  takerMarketWithRule: TakerMarketWithRecurringRule;
  setModalProps: ({
    takerMarkets,
    defaultValues,
    type,
  }: {
    takerMarkets: TakerMarket[];
    defaultValues?: Partial<RecurringRuleFormValues>;
    type?: TakerMarketGroupType;
  }) => void;
  onDelete: () => void;
}

const useTitleAndDescription = ({
  ruleCategory,
  rule,
  currency,
}: {
  ruleCategory: RecurringRuleCategory;
  rule: RecurringRule;
  currency: string;
}) => {
  const { asCurrency } = useLocaleFormat();
  const { getDpeRule, getInvoiceIdsRule, getInvoiceAmountRule, getDueDateRule } = useRuleTitles();

  switch (ruleCategory) {
    case 'dpe':
      return getDpeRule({ gteDpe: rule.dpe?.gteDpe, lteDpe: rule.dpe?.lteDpe });
    case 'invoiceId':
      return getInvoiceIdsRule(rule.excludedVoucherIds);
    case 'amount':
      return getInvoiceAmountRule({
        gteInvoiceAmount: rule.amount?.gteInvoiceAmount
          ? asCurrency(rule.amount?.gteInvoiceAmount, currency)
          : undefined,
        lteInvoiceAmount: rule.amount?.lteInvoiceAmount
          ? asCurrency(rule.amount?.lteInvoiceAmount, currency)
          : undefined,
      });
    case 'dueDate':
      return getDueDateRule({ fromDueDate: rule.dueDate?.fromDueDate, toDueDate: rule.dueDate?.toDueDate });
  }
};

const SetRuleCriteria = ({ ruleCategory, takerMarketWithRule, setModalProps, onDelete }: SetRuleCriteriaProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [preventChipHover, setPreventChipHover] = useState(false);
  const { getRestrictions } = useRestrictions();

  useEffect(() => {
    // Will only trigger on mobile devices
    window.addEventListener('touchstart', () => {
      setPreventChipHover(true);
    });
  });

  const { rule, takerMarket } = takerMarketWithRule;
  const { canEditRecurringRules } = getRestrictions([takerMarket]);

  const { title, description } =
    useTitleAndDescription({
      ruleCategory,
      rule,
      currency: takerMarket.currency,
    }) ?? {};

  const defaultValues = {
    category: ruleCategory,
    criteria: mapRecurringRuleToFormCriteria(rule)[ruleCategory],
    takerMarkets: [takerMarket],
  };

  const modalProps = {
    takerMarkets: [takerMarket],
    defaultValues,
    type: getMarketType(takerMarket),
  };

  return title && description ? (
    <DropdownMenu>
      <DropdownMenuTrigger
        aria-label={t(`recurringRules.${ruleCategory}.recurringRule`)}
        disabled={!canEditRecurringRules}
      >
        <Popover open={open}>
          <Popover.Trigger
            name="edit-rule"
            onClick={(e) => e.preventDefault()}
            className="m-auto w-min max-w-full"
            asChild
          >
            <div
              onMouseEnter={() => {
                !preventChipHover && setOpen(true);
              }}
              onMouseLeave={() => setOpen(false)}
              className="w-min"
            >
              <Chip
                type="info"
                variant="outlined"
                label={title}
                className="m-auto"
                {...(canEditRecurringRules && {
                  endIcon: { icon: EllipsisVerticalIcon, name: t(`recurringRules.${ruleCategory}.recurringRule`) },
                })}
                useHoverStyles={canEditRecurringRules}
              />
            </div>
          </Popover.Trigger>
          <Popover.Content arrow side="top">
            <div>{description}</div>
          </Popover.Content>
        </Popover>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem key="edit" onClick={() => setModalProps({ ...modalProps })}>
          {t('recurringRules.editRule')}
        </DropdownMenuItem>
        <DropdownMenuItem key="delete" onClick={onDelete}>
          {t('recurringRules.deleteRule')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : null;
};

export default SetRuleCriteria;
