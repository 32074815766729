import { useCallback } from 'react';
import { RecurringRule } from '@/data/useRecurringRules';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';

const useGetTakerMarketForRecurringRule = (rule?: RecurringRule) => {
  const { data: takerMarket } = useTakerMarkets(
    useCallback(
      (takerMarkets: TakerMarket[]) => {
        if (!rule) {
          return;
        }

        return takerMarkets.find(
          (tm) =>
            tm.makerOrganizationUuid === rule?.makerOrganizationUuid &&
            tm.takerDivisionId === rule?.takerId &&
            tm.legacyMarketId === rule?.marketId
        );
      },
      [rule]
    )
  );

  return takerMarket;
};

export default useGetTakerMarketForRecurringRule;
