import { Outlet } from 'react-router-dom';
import NavigateWithRedirect from '@/components/NavigateWithRedirect';
import useFeature, { Feature } from '@/lib/features';

export const FeatureFlagProtectedRoute = ({ featureFlag }: { featureFlag: Feature }) => {
  const [enableFeatureFlag] = useFeature(featureFlag);

  if (!enableFeatureFlag) {
    return <NavigateWithRedirect to="/supplier/markets" />;
  }

  return <Outlet />;
};

interface FeatureFlagAlternativeRouteElementProps {
  featureFlag: Feature;
  featureFlagEnabledElement: JSX.Element;
  featureFlagDisabledElement?: JSX.Element;
}

/**
 * Use if the same route should navigate to different elements based on a feature flag.
 */
export const FeatureFlagAlternativeRouteElement = ({
  featureFlag,
  featureFlagEnabledElement,
  featureFlagDisabledElement,
}: FeatureFlagAlternativeRouteElementProps) => {
  const [enableFeatureFlag] = useFeature(featureFlag);

  if (enableFeatureFlag) {
    return featureFlagEnabledElement;
  }

  return featureFlagDisabledElement;
};
