import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { Button, Chip, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import { EligibleInvoiceFetchFilters } from '@/data/useEligibleInvoices';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';

const ManualExclusionChip = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const navigate = useNavigate();
  const { track } = useReporting();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    takerExcludedInvoiceAmount: excludedAmount,
    takerExcludedInvoiceCount: excludedCount,
    currency,
  } = takerMarket;

  if (excludedAmount <= 0) {
    return null;
  }

  const handleOpenModal = () => {
    setOpenModal(true);
    track('manual-exclusion-modal::opened', {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const navParams = qs.stringify({
    limit: 100,
    order: 'amount',
    orderDirection: 'desc',
    page: 1,
    filter: { included: [false] } satisfies EligibleInvoiceFetchFilters,
  });

  return (
    <>
      <Modal onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>{t('core.manualExclusion')}</ModalTitleInverse>
        <ModalContent>
          {excludedCount === 1 && (
            <div>
              {t('taker.divisionExcludedInvoiceDescription', {
                divisionName: getTakerMarketName(takerMarket),
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount > 1 && (
            <div>
              {t('taker.divisionExcludedInvoiceDescriptionPlural', {
                divisionName: getTakerMarketName(takerMarket),
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.close')}
          </Button>
          <Button
            onClick={() => {
              track('manual-exclusion-modal::redirected');

              navigate(
                `/supplier/markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible?${navParams}`
              );
            }}
            variant="cta"
          >
            {t('core.viewExcludedInvoices')}
          </Button>
        </ModalActions>
      </Modal>
      <Chip
        label={
          <div className="text-left">
            <div>{t('core.manualExclusion')}</div>
            <div>
              {t('invoiceSettings.excludedAmount', {
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          </div>
        }
        size="xs"
        type="warning"
        variant="outlined"
        className="mt-1"
        onClick={handleOpenModal}
      />
    </>
  );
};

export default ManualExclusionChip;
