import { isProd } from './env';
import { getTokenContent } from './token';
import useNewExperienceEnabled from './useNewExperienceEnabled';

/**
 * Suppliers that log in with new experience enabled on their account configuration
 * should have a limited experience in the enterprise app. However, internal users or
 * impersonation sessions do not have the same limitations.
 */
const useSmbUser = () => {
  const isProdEnv = isProd();
  const tokenContent = getTokenContent();
  const payload = tokenContent?.payload;
  // if user does not have an account config, default new experience enabled to true
  const newExperienceEnabled = useNewExperienceEnabled();

  // only check NSE account config in PROD because account configs are minimal
  // in lower environments and we don't want to disable which experience is available
  // for certain use cases in lower environments
  if (!isProdEnv) {
    return false;
  }

  if (tokenContent) {
    if ((newExperienceEnabled || newExperienceEnabled === undefined) && payload) {
      const isImpersonation = payload.type === 'impersonation';
      const user = isImpersonation && payload.impersonationSubject ? payload.impersonationSubject : payload.user;
      const isC2foUser = user.emailAddress.includes('@c2fo.com');

      // If user has NSE enabled and is not an internal user, should have restricted enterprise access
      if (!isImpersonation && !isC2foUser) {
        return true;
      }
    }
  }

  return false;
};

export default useSmbUser;
